import BaseRepository, { BaseDTO } from "./BaseRepository";

export interface ISourceDTO {
    source_id: number,
    filename: string,
    preprocessed_on: Date,
    language: string,
    cnt_sentence: number,
    cnt_match: number,
    project_id: number,
    project: string,
    enabled: boolean
}

export interface IDictionaryDTO {
    dict_id: number,
    name: string,
    cnt_entity: number,
    cnt_match: number,
    enabled: boolean,
    used_source_id: number[],
    icon_base64string: string
}

export interface IEntityDTO {
    entity_id: number,
    dictionary: string,
    text: string,
    cnt_match: number
}

export interface IProjectDTO {
    project_id: number,
    name: string,
    description: string,
    enabled: boolean,
    used_dict_id: number[],
}

export interface ILanguageDTO {
    lang_id: number,
    isoname: string
}

export default class ListRepository {
    /** Lists all available quality levels supported by the database. 
     * Upon a match update one of these quality levels have to be submitted to the API
     * 
     * Response example:
        {
            "status": true,
            "msg": "",
            "cmd": "list-qualityids",
            "data": [
                "Ignore",
                "No",
                "Yes"
            ]
        }
     */
    public static listQualityIDs(): Promise<BaseDTO<string[]>> {
        return BaseRepository.POST<string[]>(
            "../gateway/gateway.php?cmd=list-qualityids"
        );
    }
    
    /** Lists all source documents currently in the database
     * 
     * Response example:
        {
            "status": true,
            "msg": "",
            "cmd": "list-sources",
            "data": [
                {
                    "source_id": "4",
                    "filename": "OeMZ_1_2006.pdf",
                    "preprocessed_on": "2020-11-05 10:02:50.685",
                    "language": "de",
                    "cnt_sentence": "1527",
                    "cnt_match": "2621"
                }, ...
            ]
        }
     */
    public static listSources(): Promise<BaseDTO<ISourceDTO[]>> {
        return BaseRepository.POST<ISourceDTO[]>(
            "../gateway/gateway.php?cmd=list-sources"
        );
    }

    //todo: further typings

    /** Lists all dictionaries currently in the database
     * 
     * Respone example:
        {
            "status": true,
            "msg": "",
            "cmd": "list-dictionaries",
            "data": [
                {
                    "dict_id": "1",
                    "name": "names",
                    "cnt_entity": "1208",
                    "cnt_match": "35252"
                }
            ]
        }
     */
    public static listDictionaries(): Promise<BaseDTO<IDictionaryDTO[]>> {
        return BaseRepository.POST<IDictionaryDTO[]>(
            "../gateway/gateway.php?cmd=list-dictionaries"
        );
    }

    /** Lists all entities currently in the database
     * 
     * Response example:
        {
            "status": true,
            "msg": "",
            "cmd": "list-entities",
            "data": [
                {
                    "entity_id": "9",
                    "dictionary": "names",
                    "text": "Pierre",
                    "cnt_match": "3273"
                }, ...
            ]
        }
     */
    public static listEntities(): Promise<BaseDTO<IEntityDTO[]>> {
        return BaseRepository.POST<IEntityDTO[]>(
            "../gateway/gateway.php?cmd=list-entities"
        );
    }

    /** Lists all languages currently in the database
     * 
     * Response example:
        {
            "status": true,
            "msg": "",
            "cmd": "list-languages",
            "data": [
                {
                    "lang_id": "1",
                    "isoname": "de"
                }
            ]
        }
     */
    public static listLanguages(): Promise<BaseDTO<ILanguageDTO[]>> {
        return BaseRepository.POST<ILanguageDTO[]>(
            "../gateway/gateway.php?cmd=list-languages"
        );
    }
    
    /** Lists all projects currently in the database
     * 
     * Response example:
        {
            "status": true,
            "msg": "",
            "cmd": "list-projects",
            "data": [
                {
                    "project_id": "1",
                    "name": "test",
                    "description": "blabla"
                }
            ]
        }
     */
    public static listProjects(): Promise<BaseDTO<IProjectDTO[]>> {
        return BaseRepository.POST<IProjectDTO[]>(
            "../gateway/gateway.php?cmd=list-projects"
        );
    }
}