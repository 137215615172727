import React from 'react';
import "./Table.css";

interface ITableProps {
    headers: Array<string>,
    tableRows: JSX.Element[],
    className?: string
}

interface ITableState {
}

class Table extends React.Component<ITableProps, ITableState> {
    constructor(props: ITableProps) {
      super(props);
      this.state = {
      };
    }

    render() {
        return (<>
            <table id="table" className={this.props.className ? this.props.className : ""}>
                <thead>
                    <tr>
                        { this.props.headers.map(head => { return <th>{head}</th>}) }
                    </tr>
                </thead>
                <tbody>
                        { this.props.tableRows }
                </tbody>
            </table>   
        </>);
    }
}

export default Table;
