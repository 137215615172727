import BaseRepository, { BaseDTO } from "./BaseRepository";

export interface IConfigDTO {
    config_id: number,
    name: string,
    value: string,
    description: string
}

export interface IDictTemplateDTO {
    dict_template_id: number,
    name: string,
    type: string,
    filename: string,
    description: string
}

export interface IAddDictTemplateDTO {
    name: string, 
    project_id: number,
    filename: string, 
    type: "list" | "regex", 
    description: string, 
    base64string: string
}

export interface IFileInfoDTO {
    filename: string,
    size_kB: number,
    size: number,
    md5: string
}

export interface IJavaPPStatus {
    inprogress: boolean
}

export interface IJavaPPLog {
    base64string: string
}

class PreProcessorRepository {
    public static getJavaPPStatus(): Promise<BaseDTO<IJavaPPStatus>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=pp-execgetstatus"
        );
    }

    public static StartJavaPP(projId: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=pp-execstart",
            {project_id: projId}
        );
    }

    public static getJavaPPOutput(): Promise<BaseDTO<IJavaPPLog>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=pp-execgetoutput"
        );
    }

    public static listParametersAndValues(projId: number): Promise<BaseDTO<IConfigDTO[]>> {
        return BaseRepository.POST<IConfigDTO[]>(
            "../gateway/gateway.php?cmd=pp-listparam",
            { project_id: projId }
        );
    }

    public static updateParameterValue(projId: number, configId: number, value: string): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=pp-updateparam",
            {project_id: projId, config_id: configId, value: value}
        );
    }

    public static listPendingFiles(): Promise<BaseDTO<IFileInfoDTO[]>> {
        return BaseRepository.POST<IFileInfoDTO[]>(
            "../gateway/gateway.php?cmd=pp-fileslist"
        );
    }

    public static removePendingFile(filename: string): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=pp-filesremove",
            {filename: filename}
        );
    }

    public static addPendingFile(filename: string, base64string: string): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=pp-filesadd",
            {filename: filename, base64string: base64string}
        );
    }

    public static listDictTemplates(projId: number): Promise<BaseDTO<IDictTemplateDTO[]>> {
        return BaseRepository.POST<IDictTemplateDTO[]>(
            "../gateway/gateway.php?cmd=pp-dictlist",
            {project_id: projId}
        );
    }

    public static removeDictTemplate(dictTemplateId: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=pp-dictremove",
            {dict_template_id: dictTemplateId}
        );
    }

    public static addDictTemplate(newTemplate: IAddDictTemplateDTO): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=pp-dictadd",
            newTemplate
        );
    }
}

export default PreProcessorRepository;