export interface BaseDTO<T> {
    status: boolean,
    msg: string,
    cmd: string,
    data: T | undefined
}

export default class BaseRepository {
    private static sessid: string = "";

    public static POST<T>(url: string, params?: {}): Promise<BaseDTO<T>> {
        let reqInit: RequestInit = {
            body: params ? JSON.stringify(params) : "",
            mode: "cors",
            method: "POST",
            credentials: "same-origin"
        };

        if(BaseRepository.sessid.length > 1) {
            reqInit.headers = {
                Cookie: "PHPSESSID=" + this.sessid
            }
        }

        return window.fetch(url, reqInit)
        .then(res => { return res.json(); })
        .catch(err => alert("Error" + err) /*TODO: exception handling*/);
    }

    public static GET(url: string, params?: {}): Promise<Response> {
        let reqInit: RequestInit = {
            mode: "cors",
            method: "GET",
            credentials: "same-origin"
        };

        if(BaseRepository.sessid.length > 1) {
            reqInit.headers = {
                Cookie: "PHPSESSID=" + this.sessid
            }
        }

        return window.fetch(url, reqInit);
    }
    public static setSessId(sessid: string) {
        this.sessid = sessid;
    }
    
}