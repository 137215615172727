import React from 'react';
import Admin from '../admin/Admin';
import Export from '../export/Export';
import History from '../history/History';
import Home from '../home/Home';
import AuthRepository from '../repositories/AuthRepository';
import { AdministrationIcon, DownloadIcon, HistoryIcon, HomeIcon, LogoutIcon } from './Icons';
import './Navbar.css';

interface INavbarProps {
    isAdmin: boolean
}

interface INavbarState {
    activeTab: NavbarOptionsEnum,
    currentElementViewed: JSX.Element
}

enum NavbarOptionsEnum {
    HOME = "Entity Matcher",
    HISTORY = "Edit History",
    EXPORT = "Export",
    ADMIN = "Administration",
    LOGOUT = "Logout"
}

class Navbar extends React.Component<INavbarProps, INavbarState> {
    constructor(props: INavbarProps) {
      super(props);
      this.state = {
          activeTab: NavbarOptionsEnum.HOME,
          currentElementViewed: <Home />
      };
    }

    private renderControl(title: NavbarOptionsEnum, CtrlIcon: JSX.Element):JSX.Element {
        return (
            <div onClick={() => this.handleNavbarChange(title)}
            className={"navbar-control" + (this.state.activeTab === title ? " active-nav-tab" : "")} 
            >
                {CtrlIcon}
                {title}
            </div>
        );
    }

    private handleNavbarChange(loc: NavbarOptionsEnum) {
        let render: JSX.Element = this.state.currentElementViewed;

        switch(loc) {
            case NavbarOptionsEnum.ADMIN:
                render = <Admin />;
                break;
            case NavbarOptionsEnum.EXPORT:
                render = <Export />;
                break;
            case NavbarOptionsEnum.HISTORY:
                render = <History />;
                break;
            case NavbarOptionsEnum.HOME:
                render = <Home />;
                break;
            case NavbarOptionsEnum.LOGOUT:
                AuthRepository.logout();
                break;
            default:
                console.log("error navbarchange");
                break;
        }

        this.setState({
            activeTab: loc,
            currentElementViewed: render
        });
    }

    render() {
        return (<>
            <div className="navbar-container">
                {this.renderControl(NavbarOptionsEnum.HOME, <HomeIcon className="icon" />)}
                {this.renderControl(NavbarOptionsEnum.HISTORY, <HistoryIcon className="icon"/>)}
                
                {("" + this.props.isAdmin) === "true" ? //todo i wish i would know why this doesnt work >:( */
                    <>
                    {this.renderControl(NavbarOptionsEnum.EXPORT, <DownloadIcon className="icon"/>)}
                    {this.renderControl(NavbarOptionsEnum.ADMIN, <AdministrationIcon className="icon"/>)}
                    </>
                :
                    <></>
                }

                {this.renderControl(NavbarOptionsEnum.LOGOUT, <LogoutIcon className="icon"/>)}
            </div>
            <div className="active-content-container">
                {this.state.currentElementViewed}
            </div>
        </>);
    }
}

export default Navbar;
