import BaseRepository, { BaseDTO } from "./BaseRepository";

export default class EnableDisableRepository {
    public static toggleEnableDictonary(id: number, isEnabled: boolean): Promise<BaseDTO<any>> {
        return BaseRepository.POST<any>(
            "../gateway/gateway.php?cmd=endis-dictionary",
            {dict_id: id, enable: (isEnabled+"" === "true") ? 1 : 0}
        );
    }

    public static toggleEnableDictTemplate(id: number, isEnabled: boolean): Promise<BaseDTO<any>> {
        return BaseRepository.POST<any>(
            "../gateway/gateway.php?cmd=endis-dicttemplate",
            {dict_template_id: id, enable: (isEnabled+"" === "true") ? 1 : 0}
        );
    }

    public static toggleEnableSource(id: number, isEnabled: boolean): Promise<BaseDTO<any>> {
        return BaseRepository.POST<any>(
            "../gateway/gateway.php?cmd=endis-source",
            {source_id: id, enable: (isEnabled+"" === "true") ? 1 : 0}
        );
    }

    public static toggleEnableProject(id: number, isEnabled: boolean): Promise<BaseDTO<any>> {
        return BaseRepository.POST<any>(
            "../gateway/gateway.php?cmd=endis-project",
            {project_id: id, enable: (isEnabled+"" === "true") ? 1 : 0}
        );
    }
}