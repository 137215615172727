import BaseRepository, { BaseDTO } from "./BaseRepository";

export interface IHistoryItemDTO {
    match_id: number,
    text: string,
    entity: string,
    char_start: number,
    char_len: number,
    dictionary: string,
    timestamp: Date,
    quality: string
}

export interface IGlobalHistoryItemDTO extends IHistoryItemDTO {
    quality_id: number,
    operator: string,
    operator_id: number
}

/** todo comments */
export default class HistoryRepository {
    public static getUserHistory(): Promise<BaseDTO<[IHistoryItemDTO]>> {
        return BaseRepository.POST<[IHistoryItemDTO]>(
            "../gateway/gateway.php?cmd=history-user"
        );
    }

    public static getGlobalHistory(offset?: number, limit?: number): Promise<BaseDTO<[IGlobalHistoryItemDTO]>> {
        return BaseRepository.POST<[IGlobalHistoryItemDTO]>(
            "../gateway/gateway.php?cmd=history-global",
            {offset: offset, limit: limit}
        );
    }

    public static removeMatchFromOwnHistory(ids: Array<number>): Promise<BaseDTO<undefined>> {
        return BaseRepository.POST<undefined>(
            "../gateway/gateway.php?cmd=history-reset",
            ids.map((id) => { return { match_id: id}})
        );
    }

    public static removeMatchFromOtherHistory(entries: {userId: number, matchId: number}[]): Promise<BaseDTO<undefined>> {
        return BaseRepository.POST<undefined>(
            "../gateway/gateway.php?cmd=history-global-reset",
            entries.map(entry => { return {operator_id: entry.userId, match_id: entry.matchId}})
        );
    }
}