import React from 'react';
import Table from '../../common/Table';
import PreProcessorRepository, { IAddDictTemplateDTO, IConfigDTO, IDictTemplateDTO } from '../../repositories/PreProcessorRepository';
import ReactTooltip from 'react-tooltip';
import "./ProjectSettings.css";
import { ArrowDownIcon, CheckIcon, PlusIcon, QuestionIcon } from '../../common/Icons';
import Helper from '../../common/Helper';
import ReactModal from 'react-modal';
import Dropdown from "rc-dropdown";
import Menu, { MenuItem } from "rc-menu";
import ListRepository, { IProjectDTO } from '../../repositories/ListRepository';
import { SelectInfo } from 'rc-menu/lib/interface';
import 'rc-dropdown/assets/index.css';
import "../../style/Dropdown.css";

interface IProjectSettingsProps {
}

interface IProjectSettingsState {
  allProjects: IProjectDTO[] | undefined,
  selectedProjIndex: number | undefined,
  editedParamsId: Array<number>,
  paramsAndValues: IConfigDTO[] | undefined,
  dictTemplates: IDictTemplateDTO[] | undefined,
  isModalOpen: boolean,
  addDTO: IAddDictTemplateDTO
}

class ProjectSettings extends React.Component<IProjectSettingsProps, IProjectSettingsState> {
  constructor(props: IProjectSettingsProps) {
    super(props);
    this.state = {
      allProjects: undefined,
      selectedProjIndex: undefined,
      dictTemplates: undefined,
      paramsAndValues: undefined,
      editedParamsId: new Array<number>(0),
      isModalOpen: false,
      addDTO: {
        name: "",
        project_id: -1,
        description: "",
        type: "list",
        filename: "",
        base64string: ""
      }
    };

    this.loadData();
  }

  private loadData() {
    ListRepository.listProjects().then(res => {
      if(res.status) {
        this.setState({
          allProjects: res.data || []
        });
      } else { console.error("err: occured: " + res.msg)}
    });
  }

  private loadSettings = (projIndex: number) => {
    if((this.state.allProjects || [])[projIndex]) {
      PreProcessorRepository.listDictTemplates((this.state.allProjects || [])[projIndex].project_id).then(res => {
        if(res.status) {
          this.setState({
            dictTemplates: res.data
          });
        }
      });
  
      PreProcessorRepository.listParametersAndValues((this.state.allProjects || [])[projIndex].project_id).then(res => {
        if(res.status) {
          this.setState({
            paramsAndValues: res.data
          });
        }
      });
    } else {
      this.setState({
        paramsAndValues: undefined,
        dictTemplates: undefined
      });
    }
  }

  private onSelectProject = (projIndex: number) => {
    this.setState({
      selectedProjIndex: projIndex
    }, () => this.loadSettings(projIndex));
  }
  
  private onEditParamter = (id: number) => {
    //todo focus table cell on edit click
    this.state.editedParamsId.push(id);
    this.forceUpdate();
  }
  
  private onSaveParameter = (id: number, value: string) => {
    if(this.state.selectedProjIndex !== undefined) {
      PreProcessorRepository.updateParameterValue(this.state.allProjects![this.state.selectedProjIndex].project_id, id, value).then(res => {
        if(res.status) {
          this.setState({
            editedParamsId: Helper.arrWithout<number>(id, this.state.editedParamsId, v => { return v; })
          });
        } else {
          console.error("error occured: " + res.msg);
        }
      });
    }
  }

  private onDeleteDict = (id: number) => {
    let isSureOfAction = window.confirm("Sind Sie sicher, dass Sie dieses Dictionary" +
    " Template löschen wollen? Dieser Vorgang kann nicht rückgängig gemacht werden!");
    
    if(isSureOfAction) {
      PreProcessorRepository.removeDictTemplate(id).then(res => {
        if(res.status) {
          if(this.state.selectedProjIndex !== undefined) {
            this.loadSettings(this.state.selectedProjIndex);
          }
        } else {
          console.error("err occured: " + res.msg);
        }
      });
    }
  }

  private handleOpenAddTemplateForm = () => {
    this.setState({
      isModalOpen: true
    });
  }

  private onCloseModal = () => {
    this.setState({
      isModalOpen: false,
      addDTO: {
        name: "",
        project_id: -1,
        description: "",
        type: "list",
        filename: "",
        base64string: ""
      }
    });
  }

  private handleFormInput = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    let tempDTO: IAddDictTemplateDTO = this.state.addDTO;

    switch(event.target.name) {
      case "name-input":
        tempDTO.name = event.target.value;
        break;
      case "descr-textarea":
        tempDTO.description = event.target.value;
        break;
      case "type-select":
        tempDTO.type = event.target.value as ("list" | "regex");
        break;
      case "file-input":
        let f: File = (event.target as HTMLInputElement).files![0]; 
        if(f) {
          tempDTO.filename = f.name;

          let reader = new FileReader();
          reader.onload = () => { tempDTO.base64string = btoa(reader.result?.toString()!) };
          reader.readAsBinaryString(f);  
        } else {
          console.warn("no file selected");
        }
        break;
      default:
        console.warn("dunno " + event.target.name + "; "+ event.target.value);
    }

    this.setState({
      addDTO: tempDTO
    });
  }

  private handleSubmit = () => {
    let dtoWithProjId = this.state.addDTO;
    dtoWithProjId.project_id = this.state.allProjects![this.state.selectedProjIndex!].project_id;
    if(
      this.state.addDTO.name.length > 0 &&
      this.state.addDTO.description.length > 0 &&
      this.state.addDTO.type.length > 0 &&
      this.state.addDTO.filename.length > 0 &&
      this.state.addDTO.base64string.length > 0 
    ) {
      PreProcessorRepository.addDictTemplate(dtoWithProjId).then(res => {
        if(res.status) {
          this.onCloseModal();
          if(this.state.selectedProjIndex !== undefined) {
            this.loadSettings(this.state.selectedProjIndex);
          }
        } else {
          console.error("todo Handle exception: " + res.msg);
        }
      })
    } else {
      alert("All Fields must have a value!");
    }
  }

  private renderProjDropDown = (onClick: (id: number) => void): JSX.Element => {
    return (<Menu onSelect={(info: SelectInfo) => onClick(parseInt(info.key.toString()))}>
      {(this.state.allProjects || []).map((v, i) => {
        return (
          <MenuItem 
            key={i}
            disabled={this.state.selectedProjIndex !== undefined ? 
              (this.state.allProjects![this.state.selectedProjIndex].project_id === v.project_id) 
            : false
            }
          >
              {v.name}
          </MenuItem>
        );
      })}
    </Menu>
    );
  }

  private prepareDictTemplatesForTable = (): JSX.Element[] => {
    return this.state.dictTemplates!.map(item => {
      return (<tr>
        <td>{this.generateTooltip(item.name, item.description, item.name.replace(" ", "-"))}</td>
        <td>{item.type}</td>
        <td>{item.filename}</td>
        <td>{item.description}</td>
        <td>{Helper.renderDeleteButton(() => this.onDeleteDict(item.dict_template_id))}</td>
      </tr>);
    });
  }

  private prepareParamsAndValuesForTable = (): JSX.Element[] => {
    return this.state.paramsAndValues!.map((item, i) => { 
      let isEditMode = (this.state.editedParamsId.indexOf(item.config_id) > -1);
      let curRef: HTMLSpanElement | null;
      
      return (<tr>
        <td>
          {this.generateTooltip(item.name, item.description, item.name.replace(" ", "-"))}
        </td>
        <td className={"td-no-pad " + (isEditMode ? "focus-border" : "")} >
          <span className="editable-span" contentEditable={isEditMode} ref={ref => curRef = ref}>{item.value}</span>
        </td>
        <td>
          {isEditMode ? 
            <div className={"btn-basic green-btn"} onClick={() => this.onSaveParameter(item.config_id, curRef ? curRef.innerHTML : "")}>
              <CheckIcon className="check-icon"/>
            </div>
          :
            <> {Helper.renderEditButton(() => this.onEditParamter(item.config_id))} </>
          }
        </td>
      </tr>);
      }
    );
  }

  private generateTooltip(content: string, tooltip: string, id: string): JSX.Element {
    return (
    <>
      <a data-tip data-for={"tip-"+ id}>
        {content}
        <QuestionIcon className="tooltip-icon"/>
      </a>
      <ReactTooltip delayShow={150} className="tool-tip" id={"tip-"+ id} place="top" type="dark" effect="solid">
        <span>{tooltip}</span>
      </ReactTooltip>
    </>);
  }

  render() {
      return (<>
          {this.state.allProjects ? <>
            <span className="dropdown-label">Pick a project: </span>
            <Dropdown trigger={['click']} overlay={() => this.renderProjDropDown(this.onSelectProject)} animation="slide-up">
              <div className="dropdown-button">
                {this.state.selectedProjIndex !== undefined ? (this.state.allProjects![this.state.selectedProjIndex].name) : "Select procject" }
                <ArrowDownIcon className="icon" />
              </div>
            </Dropdown>
            {this.state.selectedProjIndex !== undefined ? 
              <>{this.state.paramsAndValues && this.state.dictTemplates ?
                <>
                  <div className="preproc-settings-container">
                      <h1>Project Settings for Java Pre-Processor</h1>
                      
                      <h2>Parameters &amp; Values</h2>
                      <Table 
                        headers={["Name", "Value"]} 
                        tableRows={this.prepareParamsAndValuesForTable()} />
                      
                      <h2>Dictionary Templates</h2>
                      
                      <div className="add-btn-container">
                        <div className="btn-basic green-btn" onClick={this.handleOpenAddTemplateForm}>
                          <PlusIcon className="icon" />    
                          <span>Add Dictionary Template</span>
                        </div>
                      </div>
                      
                      <Table 
                        headers={["Name", "Type", "Filename", "Description"]} 
                        tableRows={this.prepareDictTemplatesForTable()} />
                  </div>

                  <ReactModal 
                    isOpen={this.state.isModalOpen}
                    onRequestClose={this.onCloseModal}
                    contentLabel="Add dictionary template"
                  >
                    <h3 className="add-form-title">New dictionary template</h3>
                    <form acceptCharset="utf-8">
                      <input name="name-input" type="text" placeholder="Name" onChange={this.handleFormInput} />
                      
                      <textarea name="descr-textarea" rows={5} cols={60} placeholder="Description" onChange={this.handleFormInput} />
                      <div className="select-container">
                        <label>Type: </label>
                        <select name="type-select" onChange={this.handleFormInput}>
                          <option value="list">List</option>
                          <option value="regex">Regex</option>
                        </select>
                      </div>
                      Project: {this.state.allProjects![this.state.selectedProjIndex].name}

                      <input name="file-input" type="file" onChange={this.handleFormInput} />
                    </form>
                    
                    <div className="modal-controls">
                      <div className="exit-btn" onClick={this.onCloseModal}>X</div>
                      <div className="add-btn" onClick={this.handleSubmit}>
                        <PlusIcon className="icon" />    
                        Add new Template
                      </div>
                    </div>
                  </ReactModal>
                </>          
              :
                <div className="loading" />
              } 
              </>
            :
              <h1>Select a project you want to configure</h1>
            }
          </>
          :
            <div className="loading" />
          }
      </>);
  }
}

export default ProjectSettings;
