import BaseRepository, { BaseDTO } from "./BaseRepository";

export interface IEditDictDTO {
    dict_id: number, 
    dict_name: string, 
    icon_base64string?: string
}

export default class AddDeleteRepository {
    /** Deletes source from db
     * 
     * @param id source related id to delete
     */
    public static removeSource(id: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=remove-source",
            {source_id: id}
        );
    }

    /** Deletes dictionary from db
     * 
     * @param id source related id to delete
     */
    public static removeDictionary(id: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=remove-dictionary",
            {dict_id: id}
        );
    }

    /** Deletes entity from db
     * 
     * @param id entity related id to delete
     */
    public static removeEntity(id: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=remove-entity",
            {entity_id: id}
        );
    }

    public static async removeMultipleEntities(ids: number[]): Promise<void> {
        await ids.forEach(async id => {
            await BaseRepository.POST(
                "../gateway/gateway.php?cmd=remove-entity",
                {entity_id: id}
            )
        });

        return Promise.resolve();
    }

    /** Deletes project from db
     * 
     * @param id project id to delete
     */
    public static removeProject(id: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=remove-project",
            {project_id: id}
        )
    }

    public static addProject(name: string, description: string): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=add-project",
            {project_name: name, project_description: description}
        );
    }

    public static modifyDictonary(params: IEditDictDTO) {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=mod-dictionary",
            params
        );
    }
}