import React from "react";
import { EditIcon, TrashIcon } from "./Icons";
import "./Helper.css";
import "../style/Button.css";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import ReactDOMServer from 'react-dom/server';
import DOMPurify from 'dompurify';

class Helper {
    public static renderDeleteButton(onClickCallback: () => void, className?: string): JSX.Element {
        return <div style={{padding: "10px", margin: "0px"}} className={"btn-basic red-btn "+className} onClick={onClickCallback}>
            <TrashIcon className="icon"/>
        </div>;
    }

    public static renderEditButton(onClickCallback: () => void, className?: string): JSX.Element {
        return <div className={"btn-basic blue-btn " + className} onClick={onClickCallback}>
            <EditIcon className="icon" />
        </div>;
    }

    public static arrWithout<T>(searchVal: any, arr: T[], mapToCompare: (value: T, index: number, obj: T[]) => unknown): T[] {
        let remLocal: number = arr.map(mapToCompare).indexOf(searchVal);

        if(remLocal > -1) arr.splice(remLocal, 1);
        return arr;
    }

    public static markEntity = (text: string, start: number, len: number): JSX.Element => {
        let end: number = (parseInt(""+start) + parseInt(""+ len));
        
        return (<>
            {text.slice(0, start)}
            <span className="marked-word">{text.slice(start, end)}</span>
            {text.slice(end)}
        </>);
    }

    private static markupOneWord = (text: string, start: number, len: number, index: number, isSecondColor: boolean, isTransparent: boolean): JSX.Element => {
        let end: number = (parseInt(""+start) + parseInt(""+ len));
        let colore;
        if(isSecondColor) {
            colore = " sndCol" + ((Number(index)) % 5);
        } else {
            colore = " color-" + ((Number(index)) % 5);
        }

        if(isTransparent) colore = " noColor";

        let txt = text.slice(0, start) + 
            "<span class='marked-word" + colore + "'>" + 
            text.slice(start, end) + "</span>" + 
            text.slice(end);

        return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(txt)}} />;
    }

    public static markMultipleEntites = (text: string, toMarkup: { start: number, len: number }[], indezesToBeTransparent: number[] ) => {
        let displayed = text;

        toMarkup.forEach((item, i, arr) => {
            let jsxEl;
            // this is needed to let colors in text be consistent and to let qualified matches unmarked
            let isMarkupTransparent: boolean = indezesToBeTransparent.findIndex(v => v === i) > -1; 

            if(i > 0 && arr[i].start === arr[i-1].start) {
                let start = Number(item.start) + ((Number(i)-Number(1)) * Number(41)) + (Number(1) * Number(34));
                jsxEl = Helper.markupOneWord(displayed, start, item.len, i, true, isMarkupTransparent);
            } else {
                let start = Number(item.start) + (Number(i) * Number(41));
                jsxEl = Helper.markupOneWord(displayed, start, item.len, i, false, isMarkupTransparent);
            }

            let jsxString = ReactDOMServer.renderToStaticMarkup(jsxEl);
            displayed = jsxString.slice(5, jsxString.length - 6);
        });

        return <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(displayed)}} />;
    }

    public static provideImgFromBase64(base64String: string, height: string, style?: CSSProperties) {
        if(base64String.length > 1) {
            return <img style={style} src={"data:image/png;base64, " + base64String} alt="Dictionary Icon" height={height} width="auto" />
        } else {
            return <></>;
        }
    }
} 

export default Helper;