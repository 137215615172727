import React from 'react';
import './Admin.css';
import Dicts from './subpages/Dicts';
import Entities from './subpages/Enitites';
import GlobalHistory from './subpages/GlobalHistory';
import ImportRun from './subpages/ImportRun';
import ProjectSettings from './subpages/ProjectSettings';
import Projects from './subpages/Projects';
import Sources from './subpages/Sources';
import Stats from './subpages/Stats';
import Users from './subpages/Users';

interface IAdminProps {
}

interface IAdminState {
    currentTab: AdminTabEnum,
    renderedView: JSX.Element
}

enum AdminTabEnum {
    GLOBAL_HISTORY = "Global Edit History",
    SOURCES = "Sources",
    DICTS = "Dictionary",
    ENTITIES = "Entities",
    USERS = "Users",
    IMPORT_RUN = "Run Pre-Processor",
    STATS = "Statistics",
    PREPROC = "Project settings",
    PROJECTS = "Projects"
}

/**
 * Diese Komponente enthält die Admin-Navigationsleiste und ein div, wo die selektierte Komponente angezeigt wird
 */
class Admin extends React.Component<IAdminProps, IAdminState> {
    constructor(props: IAdminProps) {
      super(props);
      this.state = {
        currentTab: AdminTabEnum.GLOBAL_HISTORY,
        renderedView: <GlobalHistory />
      };
    }

    /**
     * Wechselt zum entsprechenden Tab durch definiertes Enum
     * 
     * @param event React.MouseEvent
     * @param loc Der ausgewählte Tab
     */
    private onNavChange = (event: React.MouseEvent, loc: AdminTabEnum) => {
        event.preventDefault();

        let render: JSX.Element = <></>;
        switch(loc) {
            case AdminTabEnum.DICTS:
                render = <Dicts />
                break;
            case AdminTabEnum.ENTITIES:
                render = <Entities />;
                break;
            case AdminTabEnum.GLOBAL_HISTORY:
                render = <GlobalHistory />;
                break;
            case AdminTabEnum.IMPORT_RUN:
                render = <ImportRun />;
                break;
            case AdminTabEnum.SOURCES:
                render = <Sources />;
                break;
            case AdminTabEnum.STATS:
                render = <Stats />;
                break;
            case AdminTabEnum.USERS:
                render = <Users />;
                break;
            case AdminTabEnum.PREPROC:
                render = <ProjectSettings />;
                break;
            case AdminTabEnum.PROJECTS:
                render = <Projects />;
                break;
            default:
                render = <h1>Unknown Error occured</h1>;
        }

        this.setState({
            currentTab: loc,
            renderedView: render
        });
    }

    /**
     * Helper Funktion welche ein Reiter in der Navigationsleiste zurückgibt
     * @param name name vom Tab
     */
    private renderNav(name: AdminTabEnum): JSX.Element {
        return (
            <div 
            className={"admin-nav-item" + (name === this.state.currentTab ? " active" : "")} 
            onClick={(e) => this.onNavChange(e, name)}>
                {name}
            </div>
        );
    }

    render() {
        return (<>
            <div className="admin-nav-container">
                {this.renderNav(AdminTabEnum.GLOBAL_HISTORY)}
                {this.renderNav(AdminTabEnum.SOURCES)}
                {this.renderNav(AdminTabEnum.DICTS)}
                {this.renderNav(AdminTabEnum.ENTITIES)}
                {this.renderNav(AdminTabEnum.USERS)}
                {this.renderNav(AdminTabEnum.PROJECTS)}
                {this.renderNav(AdminTabEnum.PREPROC)}
                {this.renderNav(AdminTabEnum.IMPORT_RUN)}
                {this.renderNav(AdminTabEnum.STATS)}
            </div>
            <div className="admin-content-container">
                {this.state.renderedView}
            </div>
        </>);
    }
}

export default Admin;
