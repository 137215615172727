import MaterialTable from 'material-table';
import moment from 'moment';
import React from 'react';
import HistoryRepository, { IGlobalHistoryItemDTO } from '../../repositories/HistoryRepository';
import MatchRepository, { MatchQualityEnum } from '../../repositories/MatchRepository';
import "../../common/Helper.css";
import Helper from '../../common/Helper';

interface IGlobalHistoryProps {
}

interface IGlobalHistoryState {
  data: IGlobalHistoryItemDTO[] | undefined
}

interface ITableItemDTO {
  date: string,
  username: string,
  text: string,
  dict: string, 
  entity: string,
  quality: string,
  //data below not shown in Table -> only added to rowdata type to enhance working experience
  match_id: number,
  userId: number,
  char_start: number,
  char_len: number
}

class GlobalHistory extends React.Component<IGlobalHistoryProps, IGlobalHistoryState> {
    constructor(props: IGlobalHistoryProps) {
      super(props);
      this.state = {
        data: undefined
      };

      this.loadData();
    }

    private loadData() {
      HistoryRepository.getGlobalHistory().then(res => { //todo implement offset
        if(res.status) {
          this.setState({
              data: res.data
          });
        }
      });
    }

    private deleteRowItemOrMany = (items: ITableItemDTO | ITableItemDTO[]): Promise<void> => {
      if(Array.isArray(items)) {
        return HistoryRepository.removeMatchFromOtherHistory(items.map(v => { return { userId: v.userId, matchId: v.match_id }})).then(res => {
          if(res.status) {
            this.loadData();
            return Promise.resolve();
          } else {
            console.error("Error occured: " + res.msg);
            return Promise.reject();
          }
        });
      } else {
        return HistoryRepository.removeMatchFromOtherHistory([{userId: items.userId, matchId: items.match_id}]).then(res => {
          if(res.status) {
            this.loadData();
            return Promise.resolve();
          } else {
            console.error("Error occured: " + res.msg);
            return Promise.reject();
          }
        });
      }
    }

    private updateRowItem = (item: ITableItemDTO): Promise<void> => {
      return MatchRepository.setMatchQuality([{matchId: item.match_id, quality: item.quality}]).then(res => {
        if(res.status) {
            this.loadData();
            return Promise.resolve();
        } else {
            console.error("Error occured: " + res.msg);
            return Promise.reject();
        }
      });
    }

    private updateRowItems = (changes: Record<number, {oldData: ITableItemDTO, newData:ITableItemDTO}>): Promise<void> => {
      let arr = new Array<{matchId: number, quality: string}>();
      
      for(let key in changes) {
        if(changes[key].oldData.quality !== changes[key].newData.quality) {
          arr.push({ matchId: changes[key].newData.match_id, quality: changes[key].newData.quality});
        }
      }

      if(arr.length) {
          return MatchRepository.setMatchQuality(arr).then(res => {
              if(res.status) {
                  this.loadData();
                  return Promise.resolve();
              } else {
                  console.error("Error occured: " + res.msg);
                  return Promise.reject();
              }
          });
      } else {
          return Promise.reject("No object found");
      }
    }

    private extractDataForTable = (): ITableItemDTO[] => {
      return (this.state.data || []).map(item => {
        return {
          date: moment(item.timestamp).format("DD.MM.YYYY, hh:mm"),
          username: item.operator,
          text: item.text,
          dict: item.dictionary, 
          entity: item.entity,
          quality: item.quality,
          match_id: item.match_id,
          userId: item.operator_id,
          char_start: item.char_start,
          char_len: item.char_len
        }
      });
    }

    render() {
        return(<>{this.state.data !== undefined ?
          <MaterialTable
            options={{
                selection: true,
                search: true,
                actionsColumnIndex: 6,
                pageSize: 20,
                pageSizeOptions: [10, 20, 50]
            }}
            actions={[
                {
                    tooltip: 'Remove All Selected Quality Matches',
                    icon: 'delete',
                    onClick: (evt: any, data: ITableItemDTO | ITableItemDTO[]) => this.deleteRowItemOrMany(data)
                }
            ]}
            editable={{
              onBulkUpdate: this.updateRowItems,
              onRowUpdate: this.updateRowItem,
              onRowDelete: this.deleteRowItemOrMany
            }}
            columns={[
              { 
                title: "Date", 
                field: "date", 
                align: "center", 
                editable: () => false 
              },
              { 
                title: "User", 
                field: "username", 
                align: "center", 
                editable: () => false 
              },
              { 
                title: "Text", 
                field: "text", 
                cellStyle: {width: "99%"}, 
                editable: () => false, 
                render: (rowData: ITableItemDTO) => Helper.markEntity(rowData.text, rowData.char_start, rowData.char_len) 
              },
              { 
                title: "Dictionary", 
                field: "dict", 
                align: "center", 
                editable: () => false 
              },
              { 
                title: "Entity", 
                field: "entity", 
                align: "center", 
                editable: () => false 
              },
              { 
                title: "Answer", 
                field: "quality", 
                lookup: {"Yes": MatchQualityEnum.YES, "No": MatchQualityEnum.NO, "Ignore": MatchQualityEnum.IGNORE}
              }
            ]}
            data={this.extractDataForTable()}
            title=""
          />
        :
          <div className="loading" />
        }</>);
    }
}

export default GlobalHistory;
