import React from 'react';
import Cookies from 'universal-cookie';
import Login from '../login/Login';
import AuthRepository from '../repositories/AuthRepository';
import BaseRepository from '../repositories/BaseRepository';
import './Loading.css';

interface IAuthHelperProps {
    children: JSX.Element
}

interface IAuthHelperState {
    isLoggedIn: boolean | undefined,
    isAdmin: boolean,
    username: string,
    didMount: boolean
}

class AuthHelper extends React.Component<IAuthHelperProps, IAuthHelperState> {
    private static cookieProvider = new Cookies();

    constructor(props: any) {
      super(props);
      this.state = {
          isLoggedIn: undefined,
          isAdmin: false,
          username: "",
          didMount: false
      };

      Login.setAuthHelper(this);
    }

    componentDidMount() {
        this.loginUserWhenNotDone() //TODO: remove only for dev purposes
        .then(status => {
            console.log("User login-state: " + status)
            this.setState({
                didMount: true
            });
        }); 
    }

    public didComponentMount() {
        return this.state.didMount
    }

    public loginUserWhenNotDone(): Promise<boolean> {
      let authcook = AuthHelper.cookieProvider.get("PHPSESSID");
      
      if(authcook) {
        BaseRepository.setSessId(authcook); //sets cookie for future requests
      } 

      return AuthRepository.getCurrentUserInfo().then(res => { // this way we can guarantee the current session id is a valid one 
        console.error(res)
        let requestStatus: boolean = res.status;

        if(requestStatus) {
            this.setState({
                isLoggedIn: requestStatus,
                isAdmin: res.data?.isadmin!,
                username: res.data?.username!
            });
        } else {
            this.setState({
                isLoggedIn: requestStatus
            });
        }
        return requestStatus;
      });
    }

    render() {
        return (<>
            {this.state.isLoggedIn === undefined ?
                <div className="loading" /> 
            : 
                <>
                {this.state.isLoggedIn === true ? 
                    React.cloneElement(this.props.children, {isAdmin: this.state.isAdmin})
                : 
                    <Login />
                }
                </>
            }
        </>);
    }
}

export default AuthHelper;
