import BaseRepository, { BaseDTO } from "./BaseRepository";
import { IUserDetailsDTO } from "./UserRepository";

/** todo comments */
export default class AuthRepository {
    public static login(username: string, password: string): Promise<BaseDTO<undefined>> {
        return BaseRepository.POST<undefined>(
            "../gateway/gateway.php?cmd=login", 
            {username: username, password: password}
        );
    }

    public static getCurrentUserInfo(): Promise<BaseDTO<IUserDetailsDTO>> {
        return BaseRepository.POST<IUserDetailsDTO>(
            "../gateway/gateway.php?cmd=op-details"
        );
    }

    public static logout(): Promise<void> { //todo check if sign out was success
        return BaseRepository.POST<undefined>(
            "../gateway/gateway.php?cmd=logout"
        ).then(ret => window.location.reload());
    }
}