import React from 'react';
//import { Redirect } from 'react-router-dom';
import AuthHelper from '../common/AuthHelper';
import AuthRepository from '../repositories/AuthRepository';
import './Login.css';

interface ILoginProps {
}

interface ILoginState {
    username: string,
    password: string,
    isLoggedIn: boolean | undefined
}

class Login extends React.Component<ILoginProps, ILoginState> {
    private static authHelper: AuthHelper;

    constructor(props: ILoginProps) {
      super(props);
      this.state = {
          username: "",
          password: "",
          isLoggedIn: undefined
      };

      if(Login.authHelper.didComponentMount()) {
        Login.authHelper.loginUserWhenNotDone()
        .then(res => {
            this.setState({
                isLoggedIn: res ? res : undefined
            });
        });
      }
    }

    private handleInput(propName: string, event: React.ChangeEvent<HTMLInputElement>) {
        // let data: Array<string> = this.state.loginData
        // data[propName] = ""
        // TODO: https://stackoverflow.com/questions/35965275/how-do-i-edit-multiple-input-controlled-components-in-react
    }

    private handleUsername(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            username: event.target.value
        });
    }

    private handlePw(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: event.target.value
        });
    }

    private handleLogin(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        
        AuthRepository.login(this.state.username, this.state.password).then(
            ret => {
                let isLoggedIn = ret["status"];
                this.setState({
                    isLoggedIn: isLoggedIn
                });
                console.log(ret);
                window.location.reload();
                //TODO: routing
            }
        );     
    }
    
    public static setAuthHelper(authHelper: AuthHelper) {
        Login.authHelper = authHelper;
    }

    render() {
        return (
        <>
            {this.state.isLoggedIn === true ? 
                <h1>You will be redirected soon...</h1>
                :
                <div className="login-container">
                    <h1>Login - Entities From Sentences AI</h1>
                    
                    {this.state.isLoggedIn === false ? <p className="invalid-login-container">Falsche Anmeldeinformationen</p> : <></>}

                    <form className="login-form" onSubmit={(event) => this.handleLogin(event)} acceptCharset="utf-8">
                        <input 
                        id="usr" 
                        type="text" 
                        name="username" 
                        placeholder="mustermannm" 
                        onChange={(event) => this.handleUsername(event)} />
                        
                        <input 
                        id="pw" 
                        type="password" 
                        name="password" 
                        placeholder="••••••"  
                        onChange={(event) => this.handlePw(event)} />

                        <input type="submit" value="Login"/>
                    </form>
                </div>
            }
        </>);
    }
}

export default Login;
