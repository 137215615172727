import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import React from 'react';
import ReactModal from 'react-modal';
import Switch from "react-switch";
import Helper from '../../common/Helper';
import { ArrowDownIcon, PlusIcon, SaveIcon } from '../../common/Icons';
import Table from '../../common/Table';
import ListRepository, { IProjectDTO } from '../../repositories/ListRepository';
import UserRepository, { IUserAddDTO, IUserDetailsDTO, IUserModifyDTO } from '../../repositories/UserRepository';
import "../../style/Button.css";
import "../../style/Form.css";
import "../../style/Dropdown.css";

interface IUsersProps {
}

interface IUsersState {
  data: IUserDetailsDTO[] | undefined,
  allProjects: IProjectDTO[] | undefined,
  isAddUserModalOpen: boolean,
  addUserDTO: IUserAddDTO,
  isEditUserModalOpen: boolean,
  editUserDTO: IUserModifyDTO,
  shouldUpdatePWofUser: boolean,
  canSaveEdit: boolean
}

class Users extends React.Component<IUsersProps, IUsersState> {
    constructor(props: IUsersProps) {
      super(props);
      this.state = {
        data: undefined,
        allProjects: undefined,
        isEditUserModalOpen: false,
        isAddUserModalOpen: false,
        addUserDTO: { email: "", enabled: 0, firstname: "", isadmin: 0, lastname: "", password: "", remark: "", username:""},
        editUserDTO: {operator_id: -1 },
        shouldUpdatePWofUser: false,
        canSaveEdit: false
      };

      ListRepository.listProjects().then(res => {
        if(res.status) {
          this.setState({
            allProjects: res.data
          });
        } else {
          console.log("error " + res.msg);
        }
      })

      this.loadData(false);
    }

    private loadData(isSilent: boolean) {
      if(!isSilent) {
        this.setState({
          data: undefined
        });
      }

      UserRepository.listOperators().then(res => {
        if(res.status) {
          this.setState({
            data: res.data!
          })
        }
      });
    }


    private onOpenAddModal = () => {
      this.setState({
        isAddUserModalOpen: true
      });
    }

    private onCloseAddModal = () => {
      this.setState({
        isAddUserModalOpen: false,
        addUserDTO: { email: "", enabled: 0, firstname: "", isadmin: 0, lastname: "", password: "", remark: "", username:""},
      });
    }

    private handleNewUserFormInput = (event: React.ChangeEvent<any>) => {
      let newUsr = this.state.addUserDTO;

      switch(event.target.name) {
        case "firstname-input":
          newUsr.firstname = event.target.value;
          break;
        case "lastname-input":
          newUsr.lastname = event.target.value;
          break;
        case "username-input":
          newUsr.username = event.target.value;
          break;
        case "email-input":
          newUsr.email = event.target.value;
          break;
        case "remark-input":
          newUsr.remark = event.target.value;
          break;
        case "pw-input":
          newUsr.password = event.target.value;
      }

      this.setState({
        addUserDTO: newUsr
      });
    }

    private onAddNewUser = () => {
      if(this.allAddFieldsFilledOut()) {
        UserRepository.addOperator(this.state.addUserDTO).then(res => {
          console.log(res);
          if(res.status) {
            this.loadData(true);
            this.onCloseAddModal();
          } else {
            console.error("error occured: " + res.msg);
          }
        })
      } else {
        alert("All Fields must be filled out!");
      }
    }

    private onSetNewUserEnabled = (isChecked: boolean) => {
      let newUsr = this.state.addUserDTO;
      newUsr.enabled = isChecked ? 1 : 0;
      this.setState({
        addUserDTO: newUsr
      });
    }

    private onSetNewUserIsAdmin = (isChecked: boolean) => {
      let newUsr = this.state.addUserDTO;
      newUsr.isadmin = isChecked ? 1 : 0;
      this.setState({
        addUserDTO: newUsr
      });
    }

    private allAddFieldsFilledOut = (): boolean => {
      return (
        this.state.addUserDTO.email.length > 0&&
        this.state.addUserDTO.firstname.length > 0 &&
        this.state.addUserDTO.lastname.length > 0 &&
        this.state.addUserDTO.remark.length > 0 &&
        this.state.addUserDTO.username.length > 0 && 
        this.state.addUserDTO.password.length > 0
      );
    }


    private onRevokeProjectAccess = (userId: number, projectId: number) => {
      UserRepository.revokeUserAccessToProject(userId, projectId).then(res => {
        if(res.status) {
          this.loadData(true);
        } else {
          console.error("err occured: " + res.msg);
        }
      });
    }
    
    private onGrantProjectAccess = (userId: number, projectId: number) => {
      UserRepository.grantUserAccessToProject(userId, projectId).then(res => {
        if(res.status) {
          this.loadData(true);
        } else {
          console.error("err occured: " + res.msg);
        }
      });
    }


    private onOpenEditModal = (userToEdit: IUserDetailsDTO) => {
      this.setState({
        isEditUserModalOpen: true,
        editUserDTO: {
          operator_id: userToEdit.operator_id,
          username: userToEdit.username,
          firstname: userToEdit.firstname,
          lastname: userToEdit.lastname,
          email: userToEdit.email,
          remark: userToEdit.remark,
          enabled: (userToEdit.enabled+""==="true") ? 1 : 0,
          isadmin: (userToEdit.isadmin+""==="true") ? 1 : 0
        }
      });
    }

    private onCloseEditModal = () => {
      this.setState({
        isEditUserModalOpen: false,
        editUserDTO: {operator_id: -1},
        shouldUpdatePWofUser: false,
        canSaveEdit: false
      });
    }

    private handleEditUserFormInput = (event: React.ChangeEvent<any>) => {
      let editUser = this.state.editUserDTO;
      let canSave = this.state.canSaveEdit;

      switch(event.target.name) {
        case "firstname-input":
          editUser.firstname = event.target.value;
          break;
        case "lastname-input":
          editUser.lastname = event.target.value;
          break;
        case "username-input":
          editUser.username = event.target.value;
          break;
        case "email-input":
          editUser.email = event.target.value;
          break;
        case "remark-input":
          editUser.remark = event.target.value;
          break;
        case "change-pw-check":
          this.setState({
            shouldUpdatePWofUser: !this.state.shouldUpdatePWofUser
          });
          break;
        case "pw-input":
          editUser.password = event.target.value;
      }

      if(this.allEditFieldsFilledOut()) {
        canSave = true;
      } else { canSave = false; }

      this.setState({
        editUserDTO: editUser,
        canSaveEdit: canSave
      });
    }

    private onSetEditedUserIsAdmin = (isAdmin: boolean) => {
      let editUser = this.state.editUserDTO;
      editUser.isadmin = isAdmin ? 1 : 0;
      this.setState({
        editUserDTO: editUser
      });
    }

    private onSetEditedUserEnabled = (isEnabled: boolean) => {
      let editUser = this.state.editUserDTO;
      editUser.enabled = isEnabled ? 1 : 0;
      this.setState({
        editUserDTO: editUser
      });
    }

    private allEditFieldsFilledOut = () => {
      return (
        this.state.editUserDTO.email?.length &&
        this.state.editUserDTO.enabled !== undefined &&
        this.state.editUserDTO.firstname?.length &&
        this.state.editUserDTO.lastname?.length &&
        this.state.editUserDTO.isadmin !== undefined &&
        this.state.editUserDTO.remark?.length &&
        this.state.editUserDTO.username?.length
      );
    }

    private saveEditedUser = () => {
      let editUser = this.state.editUserDTO;
     
      if(this.allEditFieldsFilledOut()) {
        if(this.state.shouldUpdatePWofUser) {
          if(!editUser.password?.length) alert ("Password must be filled out");
        } else {
          editUser.password = undefined;
        }
        UserRepository.modifyOperator(editUser).then(res => {
          if(res.status) {
            this.loadData(false);
            this.onCloseEditModal();
          } else {
            alert("Couldn't edit user: " + res.msg);
          }
        });
      } else {
        alert("no fields should be empty");
      }
    }

    private renderProjDropDownForUser = (userId: number, userProj: IProjectDTO[]) => {
      return (
        <Menu
          selectedKeys={userProj.map(v => v.project_id+"")}
          multiple
          onDeselect={info => this.onRevokeProjectAccess(userId, parseInt(info.key.toString()))}
          onSelect={info => this.onGrantProjectAccess(userId, parseInt(info.key.toString()))}
        >
          {(this.state.allProjects || []).map(v => {
            if(v.enabled) {
              return (
                <MenuItem key={v.project_id}>
                    {v.name}
                </MenuItem>
              );
            } else { return <></>; }
          })}
        </Menu>
        );
    }

    private prepareDataForTable = (): JSX.Element[] => {
      return (this.state.data || []).map(item => {
        return (
          <tr className="data-row">
            <td>{item.username}</td>
            <td>{item.firstname}</td>
            <td>{item.lastname}</td>
            <td>{item.remark}</td>
            <td>{item.email}</td>
            <td>
              {"" + item.isadmin === "true" ?
                "All projects (Admin)" 
              :
                <Dropdown trigger={['click']} overlay={() => this.renderProjDropDownForUser(item.operator_id ,item.projects)} animation="slide-up">
                  <div className="dropdown-button">
                    {item.projects.length + " selected"}
                    <ArrowDownIcon className="icon" />
                  </div>
                </Dropdown>
              }
            </td>
            <td>{"" + item.isadmin === "true" ? "Yes" : "No"}</td> {/** so for solving it that way it didn't work else :(( */}
            <td>{"" + item.enabled === "true" ? "Yes" : "No"}</td> {/** so for solving it that way it didn't work else :(( */}
            <td> {Helper.renderEditButton(() => this.onOpenEditModal(item))}  </td>
          </tr>
        );
      })
    }

    render() {
        return (<>
          {this.state.data ? 
            <div className="admin-user-container">
              
              <div className="btn-basic-container">
                <div className="btn-basic green-btn" onClick={this.onOpenAddModal}>
                  <PlusIcon className="icon" />    
                  <span>Add new user</span>
                </div>
              </div>

              <Table headers={["Login", "First name", "Last name", "Remark", "E-Mail", "Project", "Admin", "Enabled"]} tableRows={this.prepareDataForTable()}/>

              <ReactModal 
                overlayClassName="user-modal-overlay"
                isOpen={this.state.isEditUserModalOpen}
                onRequestClose={this.onCloseEditModal}
                contentLabel="Edit User"
              >
                <h3 className="add-form-title">Edit User {this.state.editUserDTO.username}</h3>
                <form className="edit-user-form" acceptCharset="utf-8">
                  <div className="input-container">
                    <label>First name: </label>  
                    <input name="firstname-input" type="text" value={this.state.editUserDTO.firstname || ""} placeholder="Max" onChange={this.handleEditUserFormInput} />
                  </div>
                  <div className="input-container">
                    <label>Last name: </label>  
                    <input name="lastname-input" type="text" value={this.state.editUserDTO.lastname || ""} placeholder="Mustermann" onChange={this.handleEditUserFormInput} />  
                  </div>
                  <div className="input-container">
                    <label>Username: </label>  
                    <input name="username-input" type="text" value={this.state.editUserDTO.username || ""} placeholder="mustermax" onChange={this.handleEditUserFormInput} />  
                  </div>
                  <div className="input-container">
                    <label>E-Mail: </label>  
                    <input name="email-input" type="text" value={this.state.editUserDTO.email || ""} placeholder="max@muster.at" onChange={this.handleEditUserFormInput} />
                  </div>
                  <div className="input-container">
                    <label>Remark: </label>  
                    <input name="remark-input" type="text" value={this.state.editUserDTO.remark || ""} placeholder="Rekrut" onChange={this.handleEditUserFormInput} />  
                  </div>
                  <div className="input-container">
                    <label>Change PW: </label>  
                    <input name="change-pw-check" type="checkbox" value={this.state.shouldUpdatePWofUser ? 1 : 0} onChange={this.handleEditUserFormInput} /> 
                    <input name="pw-input" type="text" disabled={!this.state.shouldUpdatePWofUser ? true : false} value={this.state.editUserDTO.password || ""} placeholder="123456" onChange={this.handleEditUserFormInput} />
                  </div>

                  <div className="input-container">
                    <label>User enabled: </label>
                    <Switch 
                      className="switch"
                      name="isEnabled-switch"
                      checked={ this.state.editUserDTO.enabled === 1 } 
                      onClick={(event) => {this.onSetEditedUserEnabled(event.currentTarget.checked)}}
                      onChange={(checked, event) => {console.log(event); this.onSetEditedUserEnabled(checked)}} 
                    />
                  </div>

                  <div className="input-container">
                    <label>Is User admin: </label>
                    <Switch 
                      className="switch"
                      name="isAdmin-switch"
                      checked={ this.state.editUserDTO.isadmin === 1 } 
                      onClick={(event) => {this.onSetEditedUserIsAdmin(event.currentTarget.checked)}}
                      onChange={(checked, event) => this.onSetEditedUserIsAdmin(checked)} 
                    />
                  </div>
                </form>
                
                <div className="modal-controls">
                    <div className="exit-btn" onClick={this.onCloseEditModal}>X</div>
                    {this.state.canSaveEdit+"" === "true" ?
                      <div className={"btn-basic blue-btn"} onClick={this.saveEditedUser}>
                          <SaveIcon className="icon" />    
                          Save user changes
                      </div>
                    :
                      <div className={"btn-basic blue-btn disabled"}>
                        <SaveIcon className="icon" />    
                        Save user changes
                      </div>
                    }
                </div>
              </ReactModal>

              <ReactModal 
                overlayClassName="user-modal-overlay"
                isOpen={this.state.isAddUserModalOpen}
                onRequestClose={this.onCloseAddModal}
                contentLabel="Add User"
              >
                <h3 className="add-form-title">Add User {this.state.addUserDTO.username}</h3>
                <form className="edit-user-form" acceptCharset="utf-8">
                  <div className="input-container">
                    <label>First name: </label>  
                    <input name="firstname-input" type="text" value={this.state.addUserDTO.firstname || ""} placeholder="Max" onChange={this.handleNewUserFormInput} />
                  </div>
                  <div className="input-container">
                    <label>Last name: </label>  
                    <input name="lastname-input" type="text" value={this.state.addUserDTO.lastname || ""} placeholder="Mustermann" onChange={this.handleNewUserFormInput} />  
                  </div>
                  <div className="input-container">
                    <label>Username: </label>  
                    <input name="username-input" type="text" value={this.state.addUserDTO.username || ""} placeholder="mustermax" onChange={this.handleNewUserFormInput} />  
                  </div>
                  <div className="input-container">
                    <label>E-Mail: </label>  
                    <input name="email-input" type="text" value={this.state.addUserDTO.email || ""} placeholder="max@muster.at" onChange={this.handleNewUserFormInput} />
                  </div>
                  <div className="input-container">
                    <label>Remark: </label>  
                    <input name="remark-input" type="text" value={this.state.addUserDTO.remark || ""} placeholder="Rekrut" onChange={this.handleNewUserFormInput} />  
                  </div>
                  <div className="input-container">
                    <label>Password: </label>  
                    <input name="pw-input" type="text" value={this.state.addUserDTO.password || ""} placeholder="123456" onChange={this.handleNewUserFormInput} />
                  </div>

                  <div className="input-container">
                    <label>User enabled: </label>
                    <Switch 
                      className="switch"
                      name="isEnabled-switch"
                      checked={ this.state.addUserDTO.enabled === 1 } 
                      onClick={(event) => this.onSetNewUserEnabled(event.currentTarget.checked)}
                      onChange={(checked, event) => this.onSetNewUserEnabled(checked)} 
                    />
                  </div>

                  <div className="input-container">
                    <label>Is User admin: </label>
                    <Switch 
                      className="switch"
                      name="isAdmin-switch"
                      checked={ this.state.addUserDTO.isadmin === 1 } 
                      onClick={(event) => {this.onSetNewUserIsAdmin(event.currentTarget.checked)}}
                      onChange={(checked, event) => this.onSetNewUserIsAdmin(checked)} 
                    />
                  </div>
                </form>
                
                <div className="modal-controls">
                    <div className="exit-btn" onClick={this.onCloseAddModal}>X</div>
                    {this.allAddFieldsFilledOut() + "" === "true" ?
                      <div className={"btn-basic green-btn"} onClick={this.onAddNewUser}>
                        <PlusIcon className="icon" />    
                        <span>Add new user</span>
                      </div>
                    :
                      <div className={"btn-basic green-btn disabled"}>
                        <PlusIcon className="icon" />    
                        <span>Add new user</span>
                      </div>
                    }
                </div>
              </ReactModal>
            </div>
          :
            <div className="loading" />
          }
        </>);
    }
}

export default Users;
