import MaterialTable from 'material-table';
import React from 'react';
import AddDeleteRepository from '../../repositories/AddDeleteRepository';
import ListRepository, { IEntityDTO } from '../../repositories/ListRepository';

interface IEntitiesProps {
}

interface IEntitiesState {
  data: IEntityDTO[] | undefined
}

class Entities extends React.Component<IEntitiesProps, IEntitiesState> {
    constructor(props: IEntitiesProps) {
      super(props);
      this.state = {
        data: undefined
      };

      this.loadData(true);
    }

    private loadData = (isSilent: boolean) => {
      if(!isSilent) {
        this.setState({
          data: undefined
        });
      }

      ListRepository.listEntities().then(res => {
        if(res.status) {
          this.setState({
            data: res.data
          });
        }
      });
    }

    private deleteRowItemOrMany = (data: IEntityDTO | IEntityDTO[]) => {
      if(Array.isArray(data)) {
        data.forEach(async item => {
          await AddDeleteRepository.removeEntity(item.entity_id);
        });

        return Promise.resolve();
      } else {
        return AddDeleteRepository.removeMultipleEntities([data.entity_id])
      }
    }

    render() {
        return (<>
          {this.state.data ?
            <div className="material-table-container">

              <MaterialTable
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
                options={{
                  selection: true,
                  filtering: true,
                  sorting: true,
                  actionsColumnIndex: 3,
                  pageSize: 20,
                  pageSizeOptions: [20, 50, 100]
                }}
                actions={[
                  {
                    tooltip: 'Remove All Selected Entities',
                    icon: 'delete',
                    onClick: (evt: any, data: IEntityDTO | IEntityDTO[]) => this.deleteRowItemOrMany(data).then(res => this.loadData(true))
                  }
                ]}
                editable={{
                  onRowDelete: oldData => this.deleteRowItemOrMany(oldData).then(res => this.loadData(true))
                }}
                columns={[
                  { 
                    title: "Dictionary", 
                    field: "dictionary",
                    align: "center",
                    editable: () => false 
                  },
                  { 
                    title: "Entity", 
                    field: "text", 
                    align: "center",
                    editable: () => false 
                  },
                  { 
                    title: "Match Count", 
                    field: "cnt_match",  
                    align: "center",
                    filtering: false,
                    editable: () => false,
                  },
                ]}
                data={this.state.data || []}
                title=""
              />
            </div>
          :
            <div className="loading" />
          }
        </>);
    }
}

export default Entities;
