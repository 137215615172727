import React from "react";
import ReactTooltip from "react-tooltip";
import Helper from "../common/Helper";
import { CheckIcon, CrossIcon, SkipIcon, TrashIcon } from "../common/Icons";
import MatchRepository, { IMatchContainerDTO, INextMatchParams, MatchQualityEnum } from "../repositories/MatchRepository";
import "./SingleMatch.css";

interface ISingleMatchProps {
    activeFilters: INextMatchParams,
    onSetMatchQuality: (id: number, quality: string) => Promise<any>,
    isFilterUpdated: boolean,
    onFilterAppliedToNewMatch: () => void
}

interface ISingleMatchState {
    matchContainer: IMatchContainerDTO | undefined
}

class SingleMatch extends React.Component<ISingleMatchProps, ISingleMatchState> {
    constructor(props: ISingleMatchProps) {
        super(props);
        this.state = {
            matchContainer: undefined
        };
    }
    
    componentDidMount() {
        if(this.props.isFilterUpdated && this.props.activeFilters.project_id) {
            this.loadNewMatch();
            this.props.onFilterAppliedToNewMatch();
        }
    }

    // componentWillUnmount() {
    //     if(this.state.matchContainer !== undefined) {
    //         this.props.onSetMatchQuality(this.state.matchContainer.matches[0].match_id, "Skip");
    //     }
    // }

    componentDidUpdate(prevProps: ISingleMatchProps) {
        if(this.props.isFilterUpdated && this.props.activeFilters.project_id) {
            this.loadNewMatch();
            this.props.onFilterAppliedToNewMatch();
        }
    }

    private async loadNewMatch() {
        if(this.state.matchContainer) { this.setState({ matchContainer: undefined }); }

        MatchRepository.getNextMatch(this.props.activeFilters).then(ret => {
            if(ret.status) {
                console.log("Match loaded");
                this.setState({
                    matchContainer: ret.data
                });
            } else {
                console.error("error occured: " + ret.msg);
            }
        });
    }

    private renderControl(title: string,  tooltipText: string, CtrlIcon?: JSX.Element): JSX.Element {
        return (<>
            <a data-tip data-for={"tip-"+ title.replace(" ", "-")}>
                <div 
                    className={"quality-control " + title.toLowerCase() + "-btn"} 
                    onClick={(event) => this.handleQualityClick(title)}
                >
                    {CtrlIcon}
                    {title}
                </div>
            </a>
            <ReactTooltip delayShow={500} className="tool-tip" id={"tip-"+ title.replace(" ", "-")} place="top" type="dark" effect="solid">
                <span>{tooltipText}</span>
            </ReactTooltip>
        </>);
    }

    private handleQualityClick = (title: string) => {
        if(this.state.matchContainer?.matches[0]) {
            let match = this.state.matchContainer.matches[0];

            this.setState({
                matchContainer: undefined
            }, () => {
                this.props.onSetMatchQuality(match.match_id, title).then(() => {
                    this.loadNewMatch();
                });
            });
        }
    }

    render() {

        return(<> {this.state.matchContainer ?
            <>{this.state.matchContainer.matches && this.state.matchContainer.matches[0] ?
                <div className="single-match-container">
                    <div className="match-container">
                        <p className="match-descr">
                            The Entity "<span className="marked-word">{this.state.matchContainer.matches[0].entity}</span>" is listed in Dictionary {Helper.provideImgFromBase64(this.state.matchContainer.matches[0].icon_base64string, "36px", { marginLeft: "15px" })} <b>{this.state.matchContainer.matches[0].dictionary}</b> (Match-ID: {this.state.matchContainer.matches[0].match_id})
                        </p>
                        <p className="match-descr">With the current filter settings there are {this.state.matchContainer.nof_remaining} Matches remaining</p>
                        <p className="match-text">{Helper.markEntity(this.state.matchContainer.text || "", this.state.matchContainer.matches[0].char_start, this.state.matchContainer.matches[0].char_len)}</p>
                        <p className="match-info">
                            Source File: 
                            <a target="_blank" rel="noopener noreferrer" href={"../gateway/getfile.php?source_id=" + this.state.matchContainer.source_id}>
                                {this.state.matchContainer.filename}
                            </a> (Preprocessed {this.state.matchContainer.preprocessed_on})
                        </p>
                    </div>

                    <div className="quality-container">
                        <p>Is this a match?</p>
                        <div className="quality-control-container">
                            {this.renderControl(MatchQualityEnum.YES, "the match from the dictionary is valid", <CheckIcon className="icon" />)}
                            {this.renderControl(MatchQualityEnum.NO, "the match from the dictionary does not apply", <CrossIcon className="icon" />)}
                            {this.renderControl("Skip", "edit later", <SkipIcon className="icon" />)}
                            {this.renderControl(MatchQualityEnum.IGNORE, "no further processing - no export", <TrashIcon className="icon" />)}
                        </div>
                    </div>
                </div>
            :
                <div className="no-match-found-container">
                    <h1>Leider wurde kein Match gefunden!</h1>
                    <p>Entweder sind Sie keinem Projekt mit offenen Matches zugeordnet, oder es wurden keine Matches mit Ihren Filter Eintsellungen gefunden.</p>
                </div>
            }
            </>
        :
            <div className="loading" />
        }
        </>);
    }
}

export default SingleMatch;