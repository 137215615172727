import Dropdown from 'rc-dropdown';
import Menu, { MenuItem } from 'rc-menu';
import React from 'react';
import { ArrowDownIcon, PlayIcon, RefreshIcon } from '../../common/Icons';
import ListRepository, { IProjectDTO } from '../../repositories/ListRepository';
import PreProcessorRepository from '../../repositories/PreProcessorRepository';
import "./ImportRun.css";
import 'rc-dropdown/assets/index.css';
import "../../style/Dropdown.css";
import ImportFiles from './ImportFiles';
import "../../style/Button.css";

interface IImportRunProps {
}

interface IImportRunState {
  allProjects: IProjectDTO[] | undefined,
  selectedProjIndex: number | undefined,
  preProcStatus: boolean | undefined,
  ppLog: string,
  intervalObj: NodeJS.Timeout | undefined
}

class ImportRun extends React.Component<IImportRunProps, IImportRunState> {
    constructor(props: IImportRunProps) {
      super(props);
      this.state = {
        allProjects: undefined, 
        selectedProjIndex: undefined,
        preProcStatus: undefined,
        ppLog: "",
        intervalObj: undefined
      };
      this.reloadLog();
      this.getStatus();
      ListRepository.listProjects().then(res => {
        if(res.status) {
          this.setState({
            allProjects: res.data
          });
        } else { console.error("err occured: " + res.msg) }
      })
    }

    componentWillUnmount() {
      this.cancelIntervalling();
    }

    private cancelIntervalling = () => {
      console.log("cancceled interval");
      if(this.state.intervalObj) {
        clearInterval(this.state.intervalObj);
        this.setState({
          intervalObj: undefined
        });
      }
    }

    private startIntervalling = () => {
      if(!this.state.intervalObj) {
        let id = setInterval(() => {console.log("intervalling"); this.reloadLog(); this.getStatus()}, 2500);
        this.setState({
          intervalObj: id
        });
      }
    }

    private getStatus = () => {
      PreProcessorRepository.getJavaPPStatus().then(resStatus => {
        if(resStatus.status) {
          console.error("hmmm: " + resStatus.data!.inprogress);
          if(resStatus.data?.inprogress === false) {
            console.warn("inprogress: " + resStatus.data.inprogress);
            this.cancelIntervalling();
          }          
          this.setState({
            preProcStatus: resStatus.data?.inprogress
          });
        } else {
          console.error("error occured: " + resStatus.msg);
        }
      });
    }

    private reloadLog = () => {
      PreProcessorRepository.getJavaPPOutput().then(res => {
        this.setState({
          ppLog: atob(res.data ? res.data.base64string : "")
        });
      });
    }

    private handleStartPP = (projId: number) => {
      this.setState({
        ppLog: "..."
      });
      
      PreProcessorRepository.StartJavaPP(projId).then(res => {
        if(res.status) {
          this.startIntervalling();
        } else {
          console.error("error occured: " + res.msg);
        }
      });
    };

    private onSelectProject = (projIndex: number) => {
      this.setState({
        selectedProjIndex: projIndex
      });
    }

    private renderProjDropdown = () => {
      return (<Menu onSelect={(info) => this.onSelectProject(parseInt(info.key.toString()))}>
        {(this.state.allProjects || []).map((v, i) => {
          return (
            <MenuItem key={i}
              disabled={this.state.selectedProjIndex !== undefined ? 
                (this.state.allProjects![this.state.selectedProjIndex].project_id === v.project_id) 
              : 
                false
               }
            >
              {v.name}
            </MenuItem>);
        })}
      </Menu>);
    }

    render() {
      return (<>{this.state.preProcStatus !== undefined && this.state.allProjects? 
        <div className="pp-controller-container">
          <ImportFiles />
          <span className="dropdown-label">Pick a project: </span>
          <Dropdown trigger={['click']} overlay={this.renderProjDropdown} animation="slide-up">
            <div className="dropdown-button">
                {this.state.selectedProjIndex !== undefined ? 
                  (this.state.allProjects![this.state.selectedProjIndex].name) 
                : 
                  "Select procject" 
                }
                <ArrowDownIcon className="icon" />
            </div>
          </Dropdown>

          <p className="server-status">The background process is currently <b>{(this.state.preProcStatus+"" === "true") ? "running" : "idle"}</b>.</p>
          <p>If you start the Pre-Processor, it will start importing the uploaded files to the Server.</p>
          {this.state.selectedProjIndex !== undefined ?
            <div className={"start-pp-btn"} onClick={() => this.handleStartPP((this.state.allProjects || [])[this.state.selectedProjIndex!].project_id)}>
              <PlayIcon className="pp-icon" />
              Start Pre-Processor
            </div>
          :
            <div className="disabled-start-pp">
              <PlayIcon className="pp-icon" />
              Select Project to start Pre-Processor
            </div>  
          }
          
          <div className="log-controller">
            <span className="log-label">
              Server Log:
            </span>
            <div className="reload-log-btn" onClick={this.reloadLog}>
              <RefreshIcon className="pp-icon" />
              Reload
            </div>
          </div>
          <div className="log-container">
            {this.state.ppLog.length > 0 ? this.state.ppLog : "Loading Server log..."}
          </div>
        </div>
        :
        <div className="loading" /> 
      }</>);
    }
}

export default ImportRun;
