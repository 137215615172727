import React from 'react';
import HistoryRepository, { IGlobalHistoryItemDTO, IHistoryItemDTO } from '../repositories/HistoryRepository';
import MatchRepository, { MatchQualityEnum } from '../repositories/MatchRepository';
import './History.css';
import MaterialTable from 'material-table';
import moment from 'moment';
import Helper from '../common/Helper';

interface IHistoryProps {
}

interface IHistoryState {
    data: Array<IHistoryItemDTO | IGlobalHistoryItemDTO> | undefined
}

interface ITableItemDTO {
    match_id: number,
    date: string,
    text: string,
    char_start: number,
    char_len: number,
    dict: string, 
    entity: string,
    quality: string
}

class History extends React.Component<IHistoryProps, IHistoryState> {
    constructor(props: IHistoryProps) {
      super(props);
      this.state = {
          data: undefined
      };

      this.loadData(); 
    }

    private loadData = () => {
        this.setState({
            data: undefined
        });
        
        HistoryRepository.getUserHistory().then(ret => {
            this.setState({
                data: ret.data!
            });
        });
    }

    private updateRowItem = (obj: ITableItemDTO) => {
        return MatchRepository.setMatchQuality([{matchId: obj.match_id, quality: obj.quality}]).then(res => {
            if(res.status) {
                this.loadData();
                return Promise.resolve();
            } else {
                console.error("Error occured: " + res.msg);
                return Promise.reject();
            }
        });
    }

    private updateRowItems = (changes: Record<number, {oldData: ITableItemDTO, newData:ITableItemDTO}>) => {
        let arr = new Array<{matchId: number, quality: string}>();
        
        for(let key in changes) {
            if(changes[key].oldData.quality !== changes[key].newData.quality) {
                arr.push({ matchId: changes[key].newData.match_id, quality: changes[key].newData.quality});
            }
        }

        if(arr.length) {
            return MatchRepository.setMatchQuality(arr).then(res => {
                if(res.status) {
                    this.loadData();
                    return Promise.resolve();
                } else {
                    console.error("Error occured: " + res.msg);
                    return Promise.reject();
                }
            });
        } else {
            return Promise.reject("No object found");
        }
    }

    private deleteRowItemOrMany = (items: ITableItemDTO | ITableItemDTO[]) => {
        if(Array.isArray(items)) {
            return HistoryRepository.removeMatchFromOwnHistory(items.map(item => item.match_id)).then(res => {
                if(res.status) {
                    this.loadData();
                    return Promise.resolve();
                } else {
                    console.error("Error occured: " + res.msg);
                    return Promise.reject();
                }
            });
        } else {
            return HistoryRepository.removeMatchFromOwnHistory([items.match_id]).then(res => {
                if(res.status) {
                    this.loadData();
                    return Promise.resolve();
                } else {
                    console.error("Error occured: " + res.msg);
                    return Promise.reject();
                }
            });
        }
    }

    private extractDataForTable = (): ITableItemDTO[] => {
        return (this.state.data || []).map(item => {
            return {
                match_id: item.match_id,
                date: moment(item.timestamp).format("DD.MM.YYYY, hh:mm"),
                text: item.text,
                char_start: item.char_start,
                char_len: item.char_len,
                dict: item.dictionary, 
                entity: item.entity,
                quality: item.quality
            };
        });
    }

    render() {
        return (
            <>{this.state.data !== undefined ?
                <MaterialTable
                    options={{
                        selection: true,
                        search: true,
                        actionsColumnIndex: 5,
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50]
                    }}
                    actions={[
                        {
                            tooltip: 'Remove All Selected Quality Matches',
                            icon: 'delete',
                            onClick: (evt, data) => this.deleteRowItemOrMany(data)
                        }
                    ]}
                    editable={{
                        onBulkUpdate: changes => this.updateRowItems(changes),
                        onRowUpdate: (newData, oldData?) => { return this.updateRowItem(newData); },
                        onRowDelete: this.deleteRowItemOrMany
                    }}
                    columns={[
                    { 
                        title: "Date", 
                        field: "date", 
                        align: "center", 
                        cellStyle: {textAlign: "center"},
                        editable: () => false 
                    },
                    { 
                        title: "Text", 
                        field: "text", 
                        cellStyle: {width: "99%"}, 
                        editable: () => false, 
                        render: rowData => Helper.markEntity(rowData.text, rowData.char_start, rowData.char_len) 
                    },
                    { 
                        title: "Dictionary", 
                        field: "dict", 
                        align: "center", 
                        cellStyle: {textAlign: "center"},
                        editable: () => false 
                    },
                    { 
                        title: "Entity", 
                        field: "entity", 
                        align: "center", 
                        cellStyle: {textAlign: "center"},
                        editable: () => false 
                    },
                    { 
                        title: "Answer", 
                        field: "quality", 
                        cellStyle: {textAlign: "center"},
                        lookup: {"Yes": MatchQualityEnum.YES, "No": MatchQualityEnum.NO, "Ignore": MatchQualityEnum.IGNORE}
                    }]
                }
                data={this.extractDataForTable()}
                title=""
            />
        :
            <div className="loading" />
        }</>
        );
    }
}

export default History;
