import React from 'react';
import Helper from '../../common/Helper';
import Table from '../../common/Table';
import ListRepository, { IDictionaryDTO } from '../../repositories/ListRepository';
import Switch from "react-switch";
import AddDeleteRepository, { IEditDictDTO } from '../../repositories/AddDeleteRepository';
import EnableDisableRepository from '../../repositories/EnableDisableRepository';
import ReactModal from 'react-modal';
import { SaveIcon } from '../../common/Icons';
import "../../style/Button.css";

interface IDictsProps {
}

interface IDictsState {
    data?: IDictionaryDTO[],
    editDictDTO: IEditDictDTO,
    isEditModalOpen: boolean,
    isIconUnsetInEdit: boolean
}

class Dicts extends React.Component<IDictsProps, IDictsState> {
    constructor(props: IDictsProps) {
      super(props);
      this.state = {
        data: undefined,
        editDictDTO: {
          dict_id: -1,
          dict_name: "",
          icon_base64string: ""
        },
        isEditModalOpen: false,
        isIconUnsetInEdit: false
      };

      this.loadData(true);
    }

    private loadData = (isSilent: boolean) => {
      if(!isSilent) {
        this.setState({
          data: undefined
        });
      }

      ListRepository.listDictionaries().then(res => {
        if(res.status) {
          this.setState({
            data: res.data
          });
        } else {
          console.error("error occurred: " + res.msg);
        }
      });
    }

    private prepareDataForTable = (): JSX.Element[] => {
      return (this.state.data || []).map(item => {
        return (
          <tr className="data-row">
            <td>{item.name}</td>
            <td>{item.cnt_entity}</td>
            <td>{item.cnt_match}</td>
            <td> { Helper.provideImgFromBase64(item.icon_base64string, "50px") } </td>
            <td>
              <Switch 
                checked={ (item.enabled+"" === "true") ? true : false } 
                onClick={(event) => {this.onEnabledChange(item.dict_id, event.currentTarget.checked)}}
                onChange={(checked, event) => this.onEnabledChange(item.dict_id, checked)} />  
            </td>
            <td>{Helper.renderDeleteButton(() => this.onDeleteClick(item.dict_id), "")}</td>
            <td>{Helper.renderEditButton(() => this.onOpenEditModal(item))}</td>
          </tr>
        );
      })
    }

    private onEnabledChange = (id: number, isEnabled: boolean) => {
      EnableDisableRepository.toggleEnableDictonary(id, isEnabled).then(res => {
        if(res.status) {
          this.loadData(true);
        } else { console.log("error occured: " + res.msg)}
      })
    }

    private onDeleteClick = (id: number) => {
      let isSureOfAction = window.confirm("Sind Sie sicher, dass Sie das Dictonary löschen möchten? Dieser Vorgang ist nicht rückgängig");
      
      if(isSureOfAction) {
        AddDeleteRepository.removeDictionary(id).then(res => {
          if(res.status) {
            this.loadData(false);
          } else {
            console.error("error occurred: " + res.msg);
          }
        });
      }
    }

    private onOpenEditModal = (dictToEdit: IDictionaryDTO) => {
      this.setState({
        isEditModalOpen:true,
        editDictDTO: {
          dict_id: dictToEdit.dict_id,
          dict_name: dictToEdit.name,
          icon_base64string: dictToEdit.icon_base64string
        }
      })
    }

    private onCloseEditModal = () => {
      this.setState({
        isEditModalOpen: false,
        editDictDTO: {
          dict_id: -1,
          dict_name: "",
          icon_base64string: undefined
        }
      })
    }

    private handelFormInput = (event: React.ChangeEvent<any>) => {
      let editedDict = this.state.editDictDTO;
      console.error(event.target.name);
      console.warn(event);
      switch(event.target.name) {
        case "dict-name-input":
          editedDict.dict_name = event.target.value;
          break;
        case "icon-upload-input":
          let f: File = (event.target as HTMLInputElement).files![0]; 
          if(f) {
            if(f.size > 100000) {
              alert("Maximum icon size is 100KB!");
            } else {
              let reader = new FileReader();
              reader.onload = () => { editedDict.icon_base64string = btoa(reader.result?.toString()!); };
              reader.readAsBinaryString(f);  
            }
          } else {
            console.warn("unset file");
            editedDict.icon_base64string = undefined;
          }
          break;
        case "unset-icon-button":
          this.setState({
            isIconUnsetInEdit: true
          });
          editedDict.icon_base64string = " ";
          break;
      }

      this.setState({
        editDictDTO: editedDict
      });
    }

    private saveEdit = () => {
      AddDeleteRepository.modifyDictonary(this.state.editDictDTO).then(res => {
        if(res.status) {
          this.loadData(false);
          this.onCloseEditModal();
        } else { console.error("error occured: " + res.msg)}
      })
    }

    private canSaveEdit = () => {
      return this.state.editDictDTO.dict_name.length > 0;
    }

    render() {
        return (<>
          {this.state.data ? 
            <>
              <Table headers={["Dictionary", "Entity Count", "Match Count", "Icon", "Enabled"]} tableRows={this.prepareDataForTable()}/>

              <ReactModal 
                isOpen={this.state.isEditModalOpen}
                onRequestClose={this.onCloseEditModal}
                contentLabel="Edit Dictionary"
              >
                <h3 className="add-dict-title">Edit Dictionary {this.state.editDictDTO.dict_name}</h3>
                
                <form className="edit-user-form" acceptCharset="utf-8">
                  <div className="input-container">
                    <label>Name: </label>  
                    <input name="dict-name-input" type="text" value={this.state.editDictDTO.dict_name} placeholder="Cities" onChange={this.handelFormInput} />
                  </div>

                  <div className="input-container">
                    <label>New Icon:</label>  
                    <input name="icon-upload-input" type="file" onChange={this.handelFormInput} />  
                  </div>

                  <div className="input-container">
                    <label>Current Icon: {Helper.provideImgFromBase64(this.state.editDictDTO.icon_base64string || "", "36px")}</label>
                    <button type="button" disabled={(this.state.editDictDTO.icon_base64string || "").length < 2} name="unset-icon-button" onClick={this.handelFormInput}>Reset Icon</button>
                  </div>
                </form>

                <div className="modal-controls">
                    <div className="exit-btn" onClick={this.onCloseEditModal}>X</div>
                    {this.canSaveEdit()+"" === "true" ?
                      <div className={"btn-basic blue-btn"} onClick={this.saveEdit}>
                          <SaveIcon className="icon" />    
                          <span>Save dictionary changes</span>
                      </div>
                    :
                      <div className={"btn-basic blue-btn disabled"}>
                        <SaveIcon className="icon" />
                        <span>Save dictionary changes</span>
                      </div>
                    }
                </div>
              </ReactModal>
            
            </>
          :
            <div className="loading" />
          }
        </>);
    }
}

export default Dicts;
