import BaseRepository, { BaseDTO } from "./BaseRepository";
import { IProjectDTO } from "./ListRepository";

export interface IUserBaseDTO {
    username: string,
    enabled: boolean,
    isadmin: boolean,
    firstname: string,
    lastname: string,
    remark: string,
    email: string
}

export interface IUserDetailsDTO extends IUserBaseDTO {
    operator_id: number,
    projects: IProjectDTO[]
}

export interface IUserAddDTO {
    username: string,
    password: string,
    enabled: 1 | 0,
    isadmin: 1 | 0,
    firstname: string,
    lastname: string,
    remark: string,
    email: string
}

export interface IUserModifyDTO {
    operator_id: number,
    username?: string,
    password?: string,
    enabled?: 1 | 0,
    isadmin?: 1 | 0,
    firstname?: string,
    lastname?: string,
    remark?: string,
    email?: string
}

export default class UserRepository {
    public static listOperators(): Promise<BaseDTO<IUserDetailsDTO[]>> {
        return BaseRepository.POST<IUserDetailsDTO[]>(
            "../gateway/gateway.php?cmd=op-list"
        );
    }

    public static addOperator(usrDetails: IUserAddDTO):  Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=op-add",
            usrDetails
        );
    }

    public static modifyOperator(usrDetails: IUserModifyDTO): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=op-mod",
            usrDetails
        );
    }

    public static grantUserAccessToProject(usrId: number, projId: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=op-projgrant",
            {operator_id: usrId, project_id: projId}
        );
    }
    
    public static revokeUserAccessToProject(usrId: number, projId: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST(
            "../gateway/gateway.php?cmd=op-projrevoke",
            {operator_id: usrId, project_id: projId}
        );
    }
}