import MaterialTable from 'material-table';
import React from 'react';
import { UploadFileIcon } from '../../common/Icons';
import PreProcessorRepository, { IFileInfoDTO } from '../../repositories/PreProcessorRepository';
import "./ImportFiles.css";

interface IImportFilesProps {
}

interface IImportFilesState {
  pendingFiles: IFileInfoDTO[] | undefined,
  uploadedFilesCount: number,
  totalFilesToUploadCount: number
}

class ImportFiles extends React.Component<IImportFilesProps, IImportFilesState> {
  constructor(props: IImportFilesProps) {
    super(props);
    this.state = {
      pendingFiles: undefined,
      uploadedFilesCount: 0,
      totalFilesToUploadCount: 0
    };

    this.updateData();
  }

  private updateData() {
    PreProcessorRepository.listPendingFiles().then(res => {
      if(res.status) {
        this.setState({
          pendingFiles: res.data
        });
      }
    });
  }

  private readOutFile(f: File): Promise<{name: string, content: string}> {
    let reader = new FileReader();
    
    return new Promise<{name: string, content: string}>((resolve, reject) =>  {
      reader.onload = () => resolve({name: f.name, content: btoa(reader.result?.toString()!)});
      reader.readAsBinaryString(f);
    });
  }

  private onUploadFiles = async (event: any) => {
      event.preventDefault();

      
      let files: File[] = event.target[0].files;
      
      this.setState({
        pendingFiles: undefined,
        totalFilesToUploadCount: files.length
      });
      
      for(let file of files) {
        await this.readOutFile(file).then(async (resolve) =>  {
          await PreProcessorRepository.addPendingFile(resolve.name, resolve.content);
        });
        this.setState({
          uploadedFilesCount: this.state.uploadedFilesCount + 1
        })

      }
      this.setState({
        uploadedFilesCount: 0,
        totalFilesToUploadCount: 0
      });
      this.updateData();
  }

  private deleteRowItemOrMany = async (data: IFileInfoDTO | IFileInfoDTO[]) => {
    if(Array.isArray(data)) {
      for(let item of data) {
        await PreProcessorRepository.removePendingFile(item.filename);
      }
      return Promise.resolve();
    } else {
      return PreProcessorRepository.removePendingFile(data.filename);
    }
  }

  render() {
      return (
          <div className="import-files-container">
            <form name="file-up-form" onSubmit={this.onUploadFiles} acceptCharset="utf-8">
                <input type="file" multiple />
                <button className="upload-btn" type="submit">
                  <UploadFileIcon className="icon" />
                  Upload
                </button>
            </form>
            {this.state.pendingFiles ? 
              <>{this.state.pendingFiles.length > 0 ?
                <div className="material-table-container">
                  <MaterialTable
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                    options={{
                      selection: true,
                      filtering: true,
                      sorting: true,
                      actionsColumnIndex: 3,
                      pageSize: 5,
                      pageSizeOptions: [5, 20, 50, 100]
                    }}
                    actions={[
                      {
                        tooltip: 'Remove All Selected Files',
                        icon: 'delete',
                        onClick: (evt: any, data: IFileInfoDTO | IFileInfoDTO[]) => this.deleteRowItemOrMany(data).then(res => this.updateData())
                      }
                    ]}
                    editable={{
                      onRowDelete: oldData => this.deleteRowItemOrMany(oldData).then(res => this.updateData())
                    }}
                    columns={[
                      { 
                        title: "Filename", 
                        field: "filename",
                        align: "center",
                        editable: () => false 
                      },
                      { 
                        title: "Size in kB", 
                        field: "size_kB", 
                        align: "center",
                        editable: () => false 
                      },
                      { 
                        title: "MD5 Sum", 
                        field: "md5",  
                        align: "center",
                        filtering: false,
                        editable: () => false,
                      },
                    ]}
                    data={this.state.pendingFiles || []}
                    title="Uploaded Files ready for processing"
                  />
                </div>
              :
                <></>
              }
              </>
            :<>
              <div className="loading" />
              {this.state.totalFilesToUploadCount ?
                <div className="upload-count-container">{this.state.uploadedFilesCount} of {this.state.totalFilesToUploadCount} Files uploaded</div>
              :
                <></>
              }
            </>
            }
          </div>
      );
  }
}

export default ImportFiles;
