import React from "react";
import Helper from "../../common/Helper";
import Table from "../../common/Table";
import AddDeleteRepository from "../../repositories/AddDeleteRepository";
import ListRepository, { IProjectDTO } from "../../repositories/ListRepository";
import Switch from "react-switch";
import EnableDisableRepository from "../../repositories/EnableDisableRepository";
import ReactModal from "react-modal";
import "./ProjectSettings.css";
import { PlusIcon } from "../../common/Icons";
import "../../style/Button.css";

interface IProjectsProps {

}

interface IProjectsState {
    data: IProjectDTO[] | undefined,
    isModalOpen: boolean,
    addProjectData: {name: string, descr: string} | undefined
}

class Projects extends React.Component<IProjectsProps, IProjectsState> {
    constructor(props: IProjectsProps) {
        super(props);
        this.state = {
            data: undefined,
            isModalOpen: false,
            addProjectData: undefined
        };

        this.loadData();
    }

    private loadData() {
        ListRepository.listProjects().then(res => {
            if(res.status) {
                this.setState({
                    data: res.data || []
                })
            } else {
                console.error("error occured: " + res.msg);
            }
        })
    }

    private renderRows = (): JSX.Element[] => {
        return (this.state.data || []).map((item, i) => {
            return (<tr>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>
                    <Switch 
                        checked={ (item.enabled+"" === "true") ? true : false } 
                        onClick={(event) => {this.onEnabledChange(item.project_id, event.currentTarget.checked)}}
                        onChange={(checked, event) => this.onEnabledChange(item.project_id, checked)} />
                </td>
                <td>{Helper.renderDeleteButton(() => this.onDeleteClick(item.project_id), "")}</td>
            </tr>);
        });
    }

    private onEnabledChange = (id: number, checked: boolean) => {
        EnableDisableRepository.toggleEnableProject(id, checked).then(res => {
            if(res.status) {
                this.loadData();
            } else {
                console.error("error occured: " + res.msg);
            }
        })
    }

    private onDeleteClick = (id: number) => {
        let isSureOfAction = window.confirm("Sind Sie sicher, dass Sie das Projekt löschen möchten? Dieser Vorgang ist nicht rückgängig");
      
        if(isSureOfAction) {
            AddDeleteRepository.removeProject(id).then(res => {
                if(res.status) {
                    this.loadData();
                } else {
                    console.error("error occured: " + res.msg);
                }
            });
        }
    }

    private handleNewProjFormInput = (event: React.ChangeEvent<any>) => {
        let newProject = this.state.addProjectData || {name: "", descr: ""}; 

        switch(event.target.name) {
            case "name-input":
                newProject.name = event.target.value;
                this.setState({
                    addProjectData: newProject
                });
                break;
            case "descr-textarea":
                newProject.descr = event.target.value;
                this.setState({
                    addProjectData: newProject
                });
                break;
        }
    }

    private onCloseModal = () => {
        this.setState({
            isModalOpen: false,
            addProjectData: undefined
        });
    }

    private onOpenModal = () => {
        this.setState({
            isModalOpen: true
        });
    }

    private handleNewProject = () => {
        if(this.state.addProjectData?.name.length && this.state.addProjectData.descr.length){
            AddDeleteRepository.addProject(this.state.addProjectData.name, this.state.addProjectData.descr).then(res => {
                if(res.status) {
                    this.loadData();
                    this.onCloseModal();
                } else {
                    console.error("err occured " + res.msg);
                }
            })
        } else {
            alert("All fields must be filled out");
        }
    }

    render() {

        return <>
            <div className="btn-basic-container">
                <div className="btn-basic green-btn" onClick={this.onOpenModal}>
                    <PlusIcon className="icon" />    
                    Add new Project
                </div>
            </div>

            <ReactModal 
                isOpen={this.state.isModalOpen}
                onRequestClose={this.onCloseModal}
                contentLabel="Add project"
            >
                <h3 className="add-form-title">New project</h3>
                <form acceptCharset="utf-8">
                    <input name="name-input" type="text" placeholder="Name" onChange={this.handleNewProjFormInput} />
                    
                    <textarea name="descr-textarea" rows={12} cols={60} placeholder="Description" onChange={this.handleNewProjFormInput} />
                </form>
                
                <div className="modal-controls">
                    <div className="exit-btn" onClick={this.onCloseModal}>X</div>
                    <div className="add-btn" onClick={this.handleNewProject}>
                        <PlusIcon className="icon" />    
                        Add new project
                    </div>
                </div>
            </ReactModal>
            

            {this.state.data ? 
                <Table headers={["Name", "Beschreibung", "Aktiviert"]} tableRows={this.renderRows()} />
            :
                <div className="loading" />
            }
        </>;
    }
}

export default Projects;