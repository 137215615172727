import React from "react";
import ReactTooltip from "react-tooltip";
import Helper from "../common/Helper";
import { CheckIcon, CrossIcon, SkipIcon, TrashIcon } from "../common/Icons";
import { IMatchDTO, MatchQualityEnum } from "../repositories/MatchRepository";
import "./SingleMatch.css";
import "./QualityPicker.css";

export interface IQualityPickerProps {
    match: IMatchDTO,
    index: number,
    onQualitySetCallback: (index: number, id: number, quality: string) => Promise<any>,
}

export interface IQualityPickerState {
    isQualitySet: boolean,
    isLoading: boolean,
}

class QualityPicker extends React.Component<IQualityPickerProps, IQualityPickerState> {
    constructor(props: IQualityPickerProps) {
        super(props);
        this.state = {
            isQualitySet: false,
            isLoading: false
        };
    }
    
    private renderControl(matchId: number, title: string,  tooltipText: string, CtrlIcon?: JSX.Element): JSX.Element {
        return (<>
            <a data-tip data-for={"tip-"+ title.replace(" ", "-")}>
                <div 
                    className={"quality-control " + title.toLowerCase() + "-btn"} 
                    onClick={(event) => !this.state.isQualitySet ? this.onQualitySet(matchId, title) : {}}
                >
                    {CtrlIcon}
                    {title}
                </div>
            </a>
            <ReactTooltip class={this.state.isQualitySet ? "not-displayed" : ""} disable={this.state.isQualitySet} delayShow={500} className="tool-tip" id={"tip-"+ title.replace(" ", "-")} place="top" type="dark" effect="solid">
                <span>{tooltipText}</span>
            </ReactTooltip>
        </>);
    }

    private onQualitySet = (matchId: number, quality: string) => {
        this.setState({
            isLoading: true
        });

        this.props.onQualitySetCallback(this.props.index, matchId, quality).then(() => {
            this.setState({
                isLoading: false,
                isQualitySet: true
            })
        });
    }
    
    private renderControlsForMatch = (matchId: number): JSX.Element => {
        return (<div className="quality-control-container">
            {this.renderControl(matchId, MatchQualityEnum.YES, "the match from the dictionary is valid", <CheckIcon className="icon" />)}
            {this.renderControl(matchId, MatchQualityEnum.NO, "the match from the dictionary does not apply", <CrossIcon className="icon" />)}
            {this.renderControl(matchId, "Skip", "edit later", <SkipIcon className="icon" />)}
            {this.renderControl(matchId, MatchQualityEnum.IGNORE, "no further processing - no export", <TrashIcon className="icon" />)}
        </div>);
    }

    render() {

        return(
        <>{!this.state.isLoading ?
            <div className="quality-picker-container">
                <div className="overlay-quality-picker" style={{display: this.state.isQualitySet ? "flex" : "none"}}>Thank you for setting the quality!</div>
                <span className="match-descr"> Entity "<span className={"marked-word color-"+(Number(this.props.index)%5)}>{this.props.match.entity}</span>" is listed in Dictionary {Helper.provideImgFromBase64(this.props.match.icon_base64string, "36px", { marginLeft: "15px" })} {this.props.match.dictionary}? </span>
                <span className="is-it-match-label"> Is it a Match?</span>
                {this.renderControlsForMatch(this.props.match.match_id)}
            </div>
        :
            <div className="loading" />
        }</>);
    }
}

export default QualityPicker;