import BaseRepository, { BaseDTO } from "./BaseRepository";

export interface IAllStatsDTO {
    sources: {
        [file: string]: {
            source: string,
            Unprocessed: number,
            Yes: number,
            No: number,
            Ignore: number
        }
    },
    dictionaries: {
        [dict: string]: {
            dictionary: string,
            Ignore: number,
            No: number,
            Unprocessed: number,
            Yes: number
        }
    },
    users: {
        [uname: string]: {
            user: string,
            No: number,
            Yes: number,
            Ignore: number
        }
    }
}

class StatsRepository {
    public static getStats(): Promise<BaseDTO<IAllStatsDTO>> {
        return BaseRepository.POST<IAllStatsDTO>(
            "../gateway/gateway.php?cmd=stats-all"
        );
    }
}

export default StatsRepository;