import MaterialTable from 'material-table';
import React from 'react';
import StatsRepository, { IAllStatsDTO } from '../../repositories/StatsRepository';
import "./Stats.css";

interface IStatsProps {
}

interface IStatsState {
  allStats: IAllStatsDTO | undefined
}

class Stats extends React.Component<IStatsProps, IStatsState> {
    constructor(props: IStatsProps) {
      super(props);
      this.state = {
        allStats: undefined
      };

      this.loadData();
    }

    private loadData() {
      StatsRepository.getStats().then(res => {
        if(res.status) {
          this.setState({
            allStats: res.data
          });
        }
      });
    }

    private getDictStats = (): {
        dictionary: string,
        Total: number,
        Ignore: number,
        No: number,
        Unprocessed: number,
        Yes: number,
    }[] => {
      let arr = [];

      if(!this.state.allStats) return [];

      for(let key in this.state.allStats?.dictionaries) {
        let entry = this.state.allStats?.dictionaries[key];

        let sum: number = 0;
        sum+= entry.Yes ? parseInt(entry.Yes+"") : 0;
        sum+= entry.No ? parseInt(entry.No+"") : 0;
        sum+= entry.Ignore ? parseInt(entry.Ignore+"") : 0;
        sum+= entry.Unprocessed ? parseInt(entry.Unprocessed+"") : 0;

        arr.push({
          dictionary: entry.dictionary,
          Ignore: entry.Ignore || 0,
          Yes: entry.Ignore || 0,
          No: entry.No || 0,
          Unprocessed: entry.Unprocessed || 0,
          Total: sum
        });
      }

      return arr;
    }

    private getSourceStats = (): {
      source: string,
      Total: number,
      Ignore: number,
      No: number,
      Unprocessed: number,
      Yes: number,
    }[] => {
      let arr = [];

      if(!this.state.allStats) return [];

      for(let key in this.state.allStats?.sources) {
        let entry = this.state.allStats?.sources[key];

        let sum: number = 0;
        sum+= entry.Yes ? parseInt(entry.Yes+"") : 0;
        sum+= entry.No ? parseInt(entry.No+"") : 0;
        sum+= entry.Ignore ? parseInt(entry.Ignore+"") : 0;
        sum+= entry.Unprocessed ? parseInt(entry.Unprocessed+"") : 0;

        arr.push({
          source: entry.source,
          Ignore: entry.Ignore || 0,
          Yes: entry.Ignore || 0,
          No: entry.No || 0,
          Unprocessed: entry.Unprocessed || 0,
          Total: sum
        });
      }

      return arr;
    }

    private getUserStats = (): {
      user: string,
      Total: number,
      Ignore: number,
      No: number,
      Yes: number,
    }[] => {
      let arr = [];

      if(!this.state.allStats) return [];

      for(let key in this.state.allStats?.users) {
        let entry = this.state.allStats?.users[key];

        let sum: number = 0;
        sum+= entry.Yes ? parseInt(entry.Yes+"") : 0;
        sum+= entry.No ? parseInt(entry.No+"") : 0;
        sum+= entry.Ignore ? parseInt(entry.Ignore+"") : 0;

        arr.push({
          user: entry.user,
          Ignore: entry.Ignore || 0,
          Yes: entry.Ignore || 0,
          No: entry.No || 0,
          Total: sum
        });
      }

      return arr;
    }

    render() {
        return (<>
        {this.state.allStats ? <div className="all-table-container">
          <div className="material-table-container spacer">
            <MaterialTable
              style={{
                display: "flex",
                flexDirection: "column"
              }}
              options={{
                sorting: true,
                filtering: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100]
              }}
              columns={[
                { title: "User", field: "user", width:"99%"},
                { title: "Total", field: "Total", filtering: false },
                { title: "Positive", field: "Yes", filtering: false },
                { title: "Negative", field: "No", filtering: false },
                { title: "Ignored", field: "Ignore", filtering: false },
              ]}
              data={this.getUserStats()}
              title="Stats for users"
            />
          </div>
          <div className="material-table-container spacer">
            <MaterialTable
              style={{
                display: "flex",
                flexDirection: "column"
              }}
              options={{
                sorting: true,
                filtering: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100]
              }}
              columns={[
                { title: "Dictionary", field: "dictionary", width:"99%" },
                { title: "Total", field: "Total", filtering: false },
                { title: "Positive", field: "Yes", filtering: false },
                { title: "Negative", field: "No", filtering: false },
                { title: "Ignored", field: "Ignore", filtering: false },
                { title: "Unprocessed", field: "Unprocessed", filtering: false },
              ]}
              data={this.getDictStats()}
              title="Stats for dictionaries"
            />
          </div>
          <div className="material-table-container spacer">
            <MaterialTable
              style={{
                display: "flex",
                flexDirection: "column"
              }}
              options={{
                sorting: true,
                filtering: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100]
              }}
              columns={[
                { title: "Source", field: "source", width:"99%" },
                { title: "Total", field: "Total", filtering: false },
                { title: "Positive", field: "Yes", filtering: false },
                { title: "Negative", field: "No", filtering: false },
                { title: "Ignored", field: "Ignore", filtering: false },
                { title: "Unprocessed", field: "Unprocessed", filtering: false },
              ]}
              data={this.getSourceStats()}
              title="Stats for sources"
            />
          </div>
        </div>:
          <div className="loading" />
        }
        </>);
    }
}

export default Stats;
