import BaseRepository, { BaseDTO } from "./BaseRepository";

export interface INextMatchParams {
    lang_id?: Array<number>,
    source_id?: Array<number>,
    entity_id?: Array<number>,
    dict_id?: Array<number>,
    project_id?: Array<number>,
    words_min?: number,
    words_max?: number
}

export interface INextSentenceParams {
    lang_id?: Array<number>, 
    source_id?: Array<number>,
    project_id?: Array<number>,
    words_min?: number,
    words_max?: number
}

export interface IMatchDTO {
    match_id: number,
    entity: string,
    dictionary: string,
    dict_id: number,
    icon_base64string: string
    char_start: number,
    char_len: number
}

export interface IMatchContainerDTO {
    nof_remaining: number,
    text: string,
    filename: string,
    source_id: number,
    preprocessed_on: Date,
    matches: IMatchDTO[]
} 

export enum MatchQualityEnum {
    YES = "Yes",
    NO = "No",
    IGNORE = "Ignore"
}

export default class MatchRepository {
    /** Generates the next to-be-decided match. The API presents the text, 
     * entity, dictionary and source filename corresponding to the match. 
     * 
     * Response example: see interface IMatchContainerDTO for details
     * 
     * @param params optional Parameters for restricting available match pool
     */
    public static getNextMatch(params: INextMatchParams): Promise<BaseDTO<IMatchContainerDTO>> {
        return BaseRepository.POST<IMatchContainerDTO>(
            "../gateway/gateway.php?cmd=match-next",
            params
        );
    }

    /** This command is also intended to get the next match for the user to decide on quality
     * 
     * Response example: see interface IMatchContainerDTO for details
     *
     * @param params optional params for restricting available sentence pool
     */
    public static getNextSentence(params: INextSentenceParams): Promise<BaseDTO<IMatchContainerDTO>> {
        return BaseRepository.POST<IMatchContainerDTO>(
            "../gateway/gateway.php?cmd=match-sentence",
            params
        );
    }

    /** Write the match quality decided by the operator to the database
     * 
     * @param qualities Array of match id and quality string pair
     */
    public static setMatchQuality(qualities: Array<{matchId: number, quality: string}>): Promise<BaseDTO<void>> { //todo change quality type to enum
        return BaseRepository.POST<void>(
            "../gateway/gateway.php?cmd=match-setquality",
            qualities.map(item => { return { match_id: item.matchId, quality: item.quality} })
        )
    }

    /** When generating a match for decision by the operator (3.3.1) the match will be locked 
     * in order to avoid the concurrent processing of the same match by two different operators. 
     * This lock expires when the user logs out, writes the match quality or after 30 minutes of inactivity. 
     * Nevertheless, if the frontend code decides that the lock shall be destroyed it is possible with this function
     * 
     * @param matchId match which should be unlocked
     */
    public static unlockMatch(matchId: number): Promise<BaseDTO<any>> {
        return BaseRepository.POST<any>(
            "../gateway/gateway.php?cmd=match-unlock",
            {match_id: matchId}
        )
    }
}