import React from 'react';
import ListRepository, { IDictionaryDTO, IEntityDTO, ILanguageDTO, IProjectDTO, ISourceDTO } from '../repositories/ListRepository';
import MatchRepository, { INextMatchParams, MatchQualityEnum } from '../repositories/MatchRepository';
import Select from 'react-select';
import './Home.css';
import SingleMatch from './SingleMatch';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FilterIcon } from '../common/Icons';
import MultiMatch from './MultiMatch';
import Switch from 'react-switch';
import _ from "lodash";
var equal = require('deep-equal');

interface IHomeProps {
}

interface IHomeState {
    filters: IMatchFilter, //all values for all filters; see Interface below for details (SHOULD ONLY BE READ)
    depentendFilters: IMatchFilter, // when selecting a filter option the cross dependand filters will be loaded here
    activeFilters: INextMatchParams,
    tempFilters: INextMatchParams, //when user clicks button to apply filter values will be moved to activeFilters
    words_min_max: Array<number>, // min max word filter
    isWordLimitEnabled: boolean, // check if word count filter is enabled
    isMultiMatch: boolean, // whether user enables multiple matches or not, rendering will be affected
    areFiltersUnusedByChild: boolean, //checks if child component has gotten the change of the filters once so it can update properly
    isMounted: boolean
}

interface IMatchFilter {
    langs?: ILanguageDTO[],
    dicts?: IDictionaryDTO[],
    sources?: ISourceDTO[],
    entities?: IEntityDTO[],
    projects?: IProjectDTO[]
}

interface IFilterOptions {
    value: number;
    label: string;
}

// values are the keys of interface IMatchFilter
enum FilterEnum {
    LANGUAGE = "langs",
    DICTIONARY = "dicts",
    SOURCE = "sources",
    ENTITY = "entities",
    PROJECT = "projects"
}

/**
 * Diese Komponente beinhaltet alle Teile des Entity Matchers
 * 
 * Hier ist die gesamte Filter-Logik zu finden und, abhängig von 
 * der Auswahl wird die SingleMatch oder MultiMatch Komponente angezeigt
 */
class Home extends React.Component<IHomeProps, IHomeState> {
    private static Range = Slider.createSliderWithTooltip(Slider.Range);
    
    constructor(props: IHomeProps) {
      super(props);
      this.state = {
          filters: {},
          depentendFilters: {},
          activeFilters: {},
          tempFilters: {},
          words_min_max: [0, 250],
          isWordLimitEnabled: false,
          isMultiMatch: false,
          areFiltersUnusedByChild: false,
          isMounted: false
      };

      this.loadFilters().then((availableFilters: IMatchFilter) => {
        this.state.depentendFilters.projects = availableFilters.projects;

        this.setState({
            filters: availableFilters,
            isMounted: true
        });
      });
    }

    /**
     * Lädt alle möglichen Filteroptionen und gibt diese als Promise zurück
     * 
     * Diese Funktion wird nur im Konstruktor aufgerufen 
     */
    private async loadFilters(): Promise<IMatchFilter> {
        let allFilters: IMatchFilter = {};
        await ListRepository.listLanguages().then(ret => allFilters.langs = ret.data);
        await ListRepository.listEntities().then(ret => allFilters.entities = ret.data);
        await ListRepository.listDictionaries().then(ret => allFilters.dicts = ret.data?.filter(v => v.enabled));
        await ListRepository.listSources().then(ret => allFilters.sources = ret.data?.filter(v => v.enabled));
        await ListRepository.listProjects().then(ret => allFilters.projects = ret.data?.filter(v => v.enabled));
        return allFilters;
    }

    /**
     * Setzt die Qualität für einen Match
     * 
     * @param id Id des Matches 
     * @param quality Ausgewählte Qualität
     */
    private async setQualityForMatch(id: number, quality: string) {
        switch(quality) {
            case MatchQualityEnum.YES:
                return MatchRepository.setMatchQuality([{matchId: id, quality: MatchQualityEnum.YES}]);
            case MatchQualityEnum.NO:
                return MatchRepository.setMatchQuality([{matchId: id, quality: MatchQualityEnum.NO}]);
            case MatchQualityEnum.IGNORE:
                return MatchRepository.setMatchQuality([{matchId: id, quality: MatchQualityEnum.IGNORE}]);
            default: //default is skip -> match will be unlocked
                //await MatchRepository.unlockMatch(id);    unlocking happens automatically serverside
                return Promise.resolve();
        }
    }

    /**
     * Filter wird generiert und als HTML Element zurückgegeben
     * 
     * @param options Key Value Pair aller optionen (Key = id, Value = titel)
     * @param label Der Name des Filters
     * @param filterType Filtertyp (siehe FilterEnum)
     */
    public renderFilter = (options: IFilterOptions[], label: string, filterType: FilterEnum) => {
        return <>
            <div className="filter-container">
                <span className="filter-label">{label}</span>
                <Select 
                    isMulti 
                    classNamePrefix="select" 
                    className="filter-select" 
                    options={options}
                    onChange={(sel) => this.onFilterChange(sel as [{value: number, label: string}], filterType)} 
                />
            </div>
        </>;
    }

    /**
     * Wird eine Filteroption ausgewählt wird sie in den tempFilters State gezogen
     * Daraufhin werden die Querabhängigkeiten der einzelnen Filter berücksichtigt
     * 
     * @param selectedOption Array aller ausgewählten Filteroptionen
     * @param filterField Der jeweilige Filter
     */
    private onFilterChange = (selectedOption: IFilterOptions[], filterType: string) => {
        let ids: Array<number> = Array<number>(0);
        
        if(selectedOption != null) { 
            ids = selectedOption.map(item => { return item.value; });
        }

        let filters =  this.state.tempFilters;

        switch (filterType) {
            case FilterEnum.PROJECT:
                ids.length > 0 ? filters.project_id = ids : filters.project_id = undefined
                break;
            case FilterEnum.DICTIONARY:
                ids.length > 0 ? filters.dict_id = ids : filters.dict_id = undefined 
                break;
            case FilterEnum.SOURCE:
                ids.length > 0 ? filters.source_id = ids : filters.source_id = undefined 
                break;
            case FilterEnum.ENTITY:
                ids.length > 0 ? filters.entity_id = ids : filters.entity_id = undefined 
                break;
            case FilterEnum.LANGUAGE:
                ids.length > 0 ? filters.lang_id = ids : filters.lang_id = undefined
                break;
            default:
                break;
        }

        this.setState({
            tempFilters: filters
        }, () => this.getDependantAvailableFilters());
    };

    /** 
     * Diese Methode wird aufgerufen, wenn die Filter angepasst werden.
     * Je nach gewählten Filter Optionen sollen nur bestimmte, davon abhängige, Einträge angezeigt werden.
     * 
     * Abhängigkeiten:
     *  - Dictionaries sind Projektabhängig
     *  - Sources sind Dictionaryabängig
     *  - Entites sind Dictionaryabhängig
     *  - Languages sind Sourcesabhängig
     */
    private getDependantAvailableFilters() {
        if(this.state.tempFilters.project_id === undefined || this.state.tempFilters.project_id.length === 0) {
            this.setState({
                depentendFilters: {}
            });
            this.state.depentendFilters.projects = this.state.filters.projects;

            return;
        }

        let dependentFilters: IMatchFilter = this.state.depentendFilters;

        let allFilters: IMatchFilter = this.state.filters;
        let allowedDictIds: number[] = [];
        let allowedSourceIds: number[] = [];
        let viewedLangs: string[] = [];


        // getting each dictionary id which is linked with selected projects
        for(let proj of allFilters.projects || []) {
            if((this.state.tempFilters.project_id || []).findIndex(id => id === proj.project_id) >= 0) {
                allowedDictIds.push(...proj.used_dict_id);
            }
        }
        allowedDictIds = _.uniq(allowedDictIds);


        // getting each source id which is linked with displayed dictionaries
        let tempDicts: IDictionaryDTO[] = [];
        for(let dict of allFilters.dicts || []) {
            
            if(allowedDictIds.findIndex(id => id === dict.dict_id) >= 0) {
                if((this.state.tempFilters.dict_id || []).length === 0 || (this.state.tempFilters.dict_id || []).find(id => dict.dict_id === id) !== undefined) {
                    allowedSourceIds.push(...dict.used_source_id);
                }
                tempDicts.push(dict);
            }
        }
        dependentFilters.dicts = tempDicts;
        allowedSourceIds = _.uniq(allowedSourceIds);
        

        // getting all used languages from displayed sources
        let tempSrc: ISourceDTO[] = []
        for(let src of allFilters.sources || []) {
            
            if(allowedSourceIds.findIndex(id => id === src.source_id) >= 0) {
                viewedLangs.push(src.language);
                tempSrc.push(src);
            }
        }
        dependentFilters.sources = tempSrc;
        viewedLangs = _.uniq(viewedLangs);


        // getting all entites from available dictionaries or from selected ones 
        let tempEntities: IEntityDTO[] = [];
        let selectedDicts: IDictionaryDTO[] = dependentFilters.dicts.filter(dict => {
            return(this.state.tempFilters.dict_id || []).find(id => dict.dict_id === id) !== undefined;
        });
        for(let entity of allFilters.entities || []) {
            if(selectedDicts.length === 0) {
                if(dependentFilters.dicts.findIndex(dict => dict.name == entity.dictionary) >= 0) {
                    tempEntities.push(entity);
                }
            } else {
                if(selectedDicts.findIndex(dict => dict.name == entity.dictionary) >= 0) {
                    tempEntities.push(entity);
                }
            }
        }
        dependentFilters.entities = tempEntities;

        // getting all available languages based on selected or available sources
        let tempLangs: ILanguageDTO[] = [];
        let selectedSrcs: ISourceDTO[] = dependentFilters.sources.filter(src => {
            return (this.state.tempFilters.source_id || []).find(id => src.source_id === id) !== undefined;
        });
        for(let lang of allFilters.langs || []) {
            if(selectedSrcs.length === 0) {
                if(dependentFilters.sources.findIndex(src => src.language === lang.isoname) >= 0) {
                    tempLangs.push(lang);
                }
            } else {
                if(selectedSrcs.findIndex(src => src.language == lang.isoname) >= 0) {
                    tempLangs.push(lang);
                }
            }
        }
        dependentFilters.langs = tempLangs;

        this.setState({
            depentendFilters: dependentFilters
        });
    }

    /**
     * Callback Funktion für Single-/Multi-Match, dass sichergestellt, 
     * dass die neuen Filter Einstellungen erfasst wurden
     */
    private handleFilterApplied = () => {
        this.setState({
            areFiltersUnusedByChild: false
        });
    }

    /**
     * Wird der Regler für die Wordanzahl verändert, so wird diese Methode aufgerufen 
     * 
     * @param minAndMax Array mit 2 Items, neue min und max Werte
     */
    private onWordCountRangeChange = (minAndMax: number[]) => {
        let filters = this.state.tempFilters;
        filters.words_min = minAndMax[0];
        filters.words_max = minAndMax[1];
        
        this.setState({
            tempFilters: filters,
            words_min_max: minAndMax
        });
    }

    /**
     * Wird die Checkbox für den Wortanzahl Regler verändert, so wird diese Methode aufgerufen
     * @param event 
     */
    private onRangeEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let isChecked: boolean = event.target.checked;
        let filters = this.state.tempFilters;
        
        if(isChecked) {
            filters.words_min = this.state.words_min_max[0];
            filters.words_max = this.state.words_min_max[250];
        } else {    
            filters.words_min = undefined;
            filters.words_max = undefined;
        }
        
        this.setState({
            isWordLimitEnabled: event.target.checked,
            tempFilters: filters
        });
    }

    /**
     * Wird 'Apply Filter' gedrückt so wird mittels des areFiltersUnusedByChild-States 
     * das Child Element (Single-/Multi-Match) benachrichtigt
     * 
     * Ebenfalls wird der State tempFilters auf activeFilters gezogen, da diese jetzt beim nächsten Match berücksichtigt werden
     */
    private handleSetFilterSettings = () => {
        this.setState({
            activeFilters: Object.assign({}, this.state.tempFilters),
            areFiltersUnusedByChild: true
        });
    }

    /**
     * Prüft ob der ApplyFilter button aktiviert sein soll oder nicht
     */
    private canApplyFilter = (): boolean => {
        return !(
            (this.state.tempFilters.dict_id === undefined &&
            this.state.tempFilters.entity_id === undefined &&
            this.state.tempFilters.lang_id === undefined &&
            this.state.tempFilters.source_id === undefined &&
            this.state.tempFilters.project_id === undefined &&
            this.state.tempFilters.words_min === undefined &&
            this.state.tempFilters.words_max === undefined)
            || equal(this.state.tempFilters, this.state.activeFilters)
        );
    }

    /**
     * Wechselt der Benutzer zwischen Single- und Multimatch so wird dieses Event aufgerufen 
     * @param isChecked true, wenn multimatch, false wenn nicht
     */
    private onToggleMultiMatch = (isChecked: boolean) => {
        let temp = Object.assign({}, this.state.tempFilters);
        
        temp.entity_id = undefined;
        temp.lang_id = undefined;

        this.setState({
            isMultiMatch: isChecked,
            tempFilters: temp
        });
    }

    render() {
        const optionsLangs = this.state.depentendFilters.langs?.map(item => {
            return {value: item.lang_id, label: item.isoname};
        });
        const optionsDicts = this.state.depentendFilters.dicts?.map(item => {
            return {value: item.dict_id, label: item.name};
        });
        const optionsSources = this.state.depentendFilters.sources?.map(item => {
            return {value: item.source_id, label: item.filename + " (" + item.language + ")"};
        });
        const optionsEntities = this.state.depentendFilters.entities?.map(item => {
            return {value: item.entity_id, label: item.text + " (von: " + item.dictionary + ")"};
        });
        const optionsProjects = this.state.filters.projects?.map(item => {
            return { value: item.project_id, label: item.name };
        });
        
        return (
            <>{ this.state.isMounted  ?
                <>
                    <div className="all-filter-container">
                        {this.renderFilter(optionsProjects || [], "Projects", FilterEnum.PROJECT)}
                        {this.renderFilter(optionsDicts || [], "Dictonaries", FilterEnum.DICTIONARY)}
                        {this.renderFilter(optionsSources || [], "Sources", FilterEnum.SOURCE)}
                        <div className="flex-break-row" />
                        
                        {!this.state.isMultiMatch ? <> 
                            {this.renderFilter(optionsEntities || [], "Entities", FilterEnum.ENTITY)}
                            {this.renderFilter(optionsLangs || [], "Languages", FilterEnum.LANGUAGE)}
                        </>
                        :
                            <></>
                        }

                        <div className="word-count-slider filter-container">
                            <span className="range-label">
                                Activate word count filter: 
                                <input type="checkbox" onChange={this.onRangeEnableChange} />
                            </span>

                            <Home.Range 
                                disabled={!this.state.isWordLimitEnabled} 
                                onChange={this.onWordCountRangeChange} 
                                allowCross={false} 
                                min={0} 
                                value={this.state.words_min_max} 
                                max={1000}
                            />    
                        </div>
                        
                        <div className="flex-break-row" />
                        
                        <div>
                            Show multiple Matches
                            <Switch 
                                checked={ this.state.isMultiMatch+"" === "true" } 
                                onClick={(event) => {this.onToggleMultiMatch(event.currentTarget.checked)}}
                                onChange={(checked, event) => this.onToggleMultiMatch(checked)} 
                            />  
                        </div>
                        <div className="filter-container">
                            <div 
                                className={"btn-new " + ((this.canApplyFilter()+""=== "true") ? "apply-filter-button" : "btn-disabled")} 
                                onClick={() => {if(this.canApplyFilter()) this.handleSetFilterSettings()}}
                            >
                                <FilterIcon className="icon" />
                                Apply filter settings
                            </div>
                        </div>
                    </div>

                    {!(this.state.activeFilters.project_id === undefined || this.state.activeFilters.project_id.length < 1) ?
                    <>
                        <div style={{display: this.state.isMultiMatch ? "block" : "none"}}>
                            <MultiMatch
                                onSetMatchQuality={this.setQualityForMatch}
                                activeFilters={this.state.activeFilters}
                                isFilterUpdated={this.state.areFiltersUnusedByChild}
                                onFilterApplied={this.handleFilterApplied}
                            />    
                        </div>
                        <div style={{display: this.state.isMultiMatch ? "none" : "block"}}>
                            <SingleMatch 
                                onSetMatchQuality={this.setQualityForMatch}
                                activeFilters={this.state.activeFilters}
                                isFilterUpdated={this.state.areFiltersUnusedByChild}
                                onFilterAppliedToNewMatch={this.handleFilterApplied}
                            />
                        </div>
                    </>
                    :
                        <h1>Filter a project you want to work on</h1>
                    }
                </>
            :
                <div className="loading" />
            }
            </>
        );
    }
}

export default Home;
