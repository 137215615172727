import React from 'react';
import AddDeleteRepository from '../../repositories/AddDeleteRepository';
import ListRepository, { ISourceDTO } from '../../repositories/ListRepository';
import MaterialTable from 'material-table';
import EnableDisableRepository from '../../repositories/EnableDisableRepository';

interface ISourcesProps {
}

interface ISourcesState {
  data: ISourceDTO[] | undefined
}

class Sources extends React.Component<ISourcesProps, ISourcesState> {
    constructor(props: ISourcesProps) {
      super(props);
      this.state = {
        data: undefined
      };

      this.loadData();
    }

    /**
     * Holt Daten vom Backend und setzt es im State
     */
    private loadData = () => {
      ListRepository.listSources().then(res => {
        if(res.status) {
          this.setState({
            data: res.data!
          })
        }
      });
    }

    /**
     * Event, welches abgefeuert wird, wenn Einträge gelöscht werden
     * @param items Die gelöschten Elemente
     */
    private deleteRowItemOrMany = async (items: ISourceDTO | ISourceDTO[]) => {
      if(Array.isArray(items)) {
        let ids = items.map(v => v.source_id);
        await ids.forEach(async id => {
          await AddDeleteRepository.removeSource(id)
        });
       
      } else {
        return AddDeleteRepository.removeSource(items.source_id).then(res => {
          if(res.status) {
            return Promise.resolve();
          } else {
            console.error("Error occured: " + res.msg);
            return Promise.reject();
          }
        });
      }
    }

    /**
     * Event welches abgefeuert wird, wenn eine Zeile geändert wird 
     * ID bleibt gleich somit weiß man um welchen Eintrag es sich handelt
     * @param item das neue Objekt
     */
    private updateRowItem = (item: ISourceDTO): Promise<void> => {
      return EnableDisableRepository.toggleEnableSource(item.source_id, item.enabled).then(res => {
        if(res.status) {
            return Promise.resolve();
        } else {
            console.error("Error occured: " + res.msg);
            return Promise.reject();
        }
      });
    }

    /**
     * Event welches, wenn mehrere Einträge upgedated werden, abgefeuert wird.
     * ID bleibt gleich somit weiß man um welchen Eintrag es sich handelt
     * @param changes die Änderungen
     */
    private updateRowItems = async (changes: Record<number, {oldData: ISourceDTO, newData: ISourceDTO}>): Promise<any> => {
      for(let key in changes) {
        await EnableDisableRepository.toggleEnableSource(changes[key].newData.source_id, changes[key].newData.enabled);
      }

      return Promise.resolve();
    }

    /**
     * Generiert ein Key-Value Pairs Objekt, welches für die 'Project spalte verwendet wird'
     */
    private generateProjectLookup = () => {
      let elem: any = {};
      (this.state.data || []).map(v => {
        elem[v.project_id] = v.project;
      });
      return elem;
    }

    render() {
      return (<>
        {this.state.data ?
          <div className="material-table-container">
            <MaterialTable
              style={{
                display: "flex",
                flexDirection: "column"
              }}
              options={{
                selection: true,
                filtering: true,
                sorting: true,
                actionsColumnIndex: 6,
                pageSize: 20,
                pageSizeOptions: [20, 50, 100]
              }}
              actions={[
                {
                  tooltip: 'Remove All Selected Sources',
                  icon: 'delete',
                  onClick: (evt: any, data: ISourceDTO | ISourceDTO[]) => this.deleteRowItemOrMany(data).then(res => this.loadData())
                }
              ]}
              editable={{
                onRowDelete: oldData => this.deleteRowItemOrMany(oldData).then(res => this.loadData()),
                onRowUpdate: (newData, oldData) => this.updateRowItem(newData).then(res => this.loadData()),
                onBulkUpdate: changes => this.updateRowItems(changes).then(res => this.loadData())
              }}
              columns={[
                {
                  title: "Project",
                  field: "project_id",
                  lookup: this.generateProjectLookup()
                },
                { 
                  title: "Filename", 
                  field: "filename",
                  width: "99%",
                  render: rowData => {return <a target="_blank" rel="noopener noreferrer" href={"../gateway/getfile.php?source_id=" + rowData.source_id}>{rowData.filename}</a>},
                  editable: () => false 
                },
                { 
                  title: "Import Date", 
                  type: "date",
                  field: "preprocessed_on", 
                  editable: () => false 
                },
                { 
                  title: "Language",
                  field: "language", 
                  editable: () => false 
                },
                { 
                  title: "Sentence Count",
                  field: "cnt_sentence", 
                  filtering: false,
                  editable: () => false 
                },
                { 
                  title: "Match Count", 
                  field: "cnt_match",  
                  filtering: false,
                  editable: () => false,
                },
                { 
                  title: "Enabled", 
                  field: "enabled",
                  type: "boolean", 
                  lookup: { true: "Yes", false: "No" },
                  render: data =>  { return (data.enabled+"" === "true") ? "Yes" : "No" },
                  editable: () => true,
                },
              ]}
              data={this.state.data || []}
              title=""
            />
          </div>
        :
          <div className="loading" />
        }
      </>);
    }
}

export default Sources;
