import React from "react";
import Helper from "../common/Helper";
import MatchRepository, { IMatchContainerDTO, INextMatchParams } from "../repositories/MatchRepository";
import QualityPicker from "./QualityPicker";
import "./SingleMatch.css";

interface IMultiMatchProps {
    activeFilters: INextMatchParams,
    isFilterUpdated: boolean,
    onFilterApplied: () => void // is called when new match was load with new filter props
    onSetMatchQuality: (id: number, quality: string) => Promise<any>,
}

interface IMultiMatchState {
    matchContainer: IMatchContainerDTO | undefined,
    qualityPicker: JSX.Element[],
    disableMarkupOnIndezes: number[]
}

class MultiMatch extends React.Component<IMultiMatchProps, IMultiMatchState> {
    constructor(props: IMultiMatchProps) {
        super(props);
        
        this.state = {
            matchContainer: undefined,
            qualityPicker: [],
            disableMarkupOnIndezes: []
        };
    }

    componentDidMount() {
        if(this.props.isFilterUpdated && this.props.activeFilters.project_id) {
            this.loadNewMatch(false);
            this.props.onFilterApplied();
        }
    }

    componentDidUpdate(prevProps: IMultiMatchProps) {
        if(this.props.isFilterUpdated && this.props.activeFilters.project_id) {
            this.loadNewMatch(false);
            this.props.onFilterApplied(); 
        }
    }

    private loadNewMatch(isSilent: boolean) {
        if(!isSilent) this.setState({ matchContainer: undefined });

        MatchRepository.getNextSentence(this.props.activeFilters).then(res => {
            if(res.status) {
                console.log(res.data);
                let tempContainer: IMatchContainerDTO | undefined = res.data;
                if(tempContainer) {
                    tempContainer.matches = tempContainer.matches.sort((a, b) => a.char_start - b.char_start);
                }
                this.setState({
                    matchContainer: tempContainer,
                    disableMarkupOnIndezes: []
                }, this.populateQualityPickerState);
            } else {
                console.log("error occured: " + res.msg);
            }
        })
    }

    private handleQualityClick = (index: number, matchId: number, quality: string) => {
        return this.props.onSetMatchQuality(matchId, quality).then(() => {
            let notMarkedUp = this.state.disableMarkupOnIndezes;
            notMarkedUp.push(index);
            this.setState({
                disableMarkupOnIndezes: notMarkedUp
            });

            if(this.state.disableMarkupOnIndezes.length === this.state.matchContainer?.matches.length) this.loadNewMatch(false);
        });
    }

    private populateQualityPickerState = () =>{
        let arr: JSX.Element[] = [];

        if(this.state.matchContainer) {
            this.state.matchContainer.matches.forEach((item, i) => {
                let picker = <>
                    { i % 3 === 0 ? <div className="break-row" /> : <></> }
                    <QualityPicker match={item} index={i} onQualitySetCallback={this.handleQualityClick} />
                </>;
                arr.push(picker);
            });
        }

        this.setState({
            qualityPicker: arr
        });
    }

    render() {
        return(<>
            {this.state.matchContainer ?
            <>{this.state.matchContainer.matches ?
                <div className="single-match-container">
                    <div className="match-container">
                        <p className="match-descr">With the current filter settings there are {this.state.matchContainer.nof_remaining} Matches remaining</p>
                        <p className="match-text">
                            {Helper.markMultipleEntites(
                                this.state.matchContainer.text || "", 
                                this.state.matchContainer.matches.map(item => {return {start: item.char_start, len: item.char_len};}),
                                this.state.disableMarkupOnIndezes
                            )}
                        </p>
                        <p className="match-info">
                            Source File: 
                            <a target="_blank" rel="noopener noreferrer" href={"../gateway/getfile.php?source_id=" + this.state.matchContainer.source_id}>
                                {this.state.matchContainer.filename}
                            </a> (Preprocessed {this.state.matchContainer.preprocessed_on}) <div className="btn-basic blue-btn" style={{display: "inline"}} onClick={e => this.loadNewMatch(false)}>Skip Matches</div>
                        </p>
                    </div>

                    <div className="all-picker-container">{this.state.qualityPicker}</div>
                </div>
            :
                <div className="no-match-found-container">
                    <h1>Leider wurde kein Match gefunden!</h1>
                    <p>Entweder sind Sie keinem Projekt mit offenen Matches zugeordnet, oder es wurden keine Matches mit Ihren Filter Eintsellungen gefunden.</p>
                </div>
            }
            </>
        :
            <div className="loading" />
        }
        </>);
    }
}

export default MultiMatch;