import BaseRepository, { BaseDTO } from "./BaseRepository";

export interface IExportParams {
    lang_id?: Array<number>,
    dict_id?: Array<number>,
    project_id?: Array<number>,
    words_min?: number,
    words_max?: number
}

export default class ExportRepository {
    /** Generates the next to-be-decided match. The API presents the text, 
     * entity, dictionary and source filename corresponding to the match. 
     * 
     * Response example: see interface IMatchContainerDTO for details
     * 
     * @param params optional Parameters for restricting available match pool
     */
    public static getXMI(params: IExportParams): Promise<any> {
        let fileName : string;
    // return window.fetch("../gateway/getfile.php?id="+params.project_id?.[0]);
        let query = "../gateway/getfile.php?";
        if(params.project_id) query += "id=" + params.project_id?.join(",") + "&";
        query += "dict_id=" + (params.dict_id || []).join(",") + "&";
        query += "words_min=" + params.words_min + "&";
        query += "words_min=" + params.words_max;

        debugger;

         return BaseRepository.GET("../gateway/getfile.php?id="+params.project_id?.[0])
         .then((response:Response) => {
            //https://stackoverflow.com/a/40940790
            let disposition = response.headers.get("content-disposition")
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                  fileName = matches[1].replace(/['"]/g, '');
                }
            };

            return response.blob();
        })
        .then(blob => {
            //https://stackoverflow.com/a/42274086
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = fileName ? fileName : "export.zip";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            window.open ("https://mle4s.cdfz.at/build/index.html", "_self");
            a.remove();  //afterwards we remove the element again         
        });
        // "../gateway/getfile.php?id="+params.project_id?.[0], {}, true
        //     // params
        // );
        
    }
}